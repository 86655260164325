<template>
  <v-expansion-panel>
    <v-expansion-panel-title>Stochastic Rsi</v-expansion-panel-title>
    <v-expansion-panel-text>
      <v-sheet class="mx-auto">
        <v-row no-gutters>
          <v-col cols="6" sm="3">
            <v-text-field v-model="stateValues.stochasticRSI.period" label="RSI Period" required></v-text-field>
          </v-col>
          <v-col cols="6" sm="3">
            <v-text-field v-model="stateValues.stochasticRSI.stochPeriod" label="S. RSI Period" required></v-text-field>
          </v-col>
          <v-col cols="6" sm="3">
            <v-text-field v-model="stateValues.stochasticRSI.kPeriod" label="K Line Period" required></v-text-field>
          </v-col>
          <v-col cols="6" sm="3">
            <v-text-field v-model="stateValues.stochasticRSI.dPeriod" label="D Line Period" required></v-text-field>
          </v-col>
          <v-col v-if="stateValues.general.rotationValue === 'e' || stateValues.general.rotationValue === 'p'" cols="6"
            :sm="stateValues.general.rotationValue === 'e' ? 4 : 6">
            <v-text-field v-model="stateValues.stochasticRSI.oversold" label="Oversold limit less than" required></v-text-field>
          </v-col>
          <v-col v-if="stateValues.general.rotationValue === 'e' || stateValues.general.rotationValue === 'n'" cols="6"
            :sm="stateValues.general.rotationValue === 'e' ? 4 : 6">
            <v-text-field v-model="stateValues.stochasticRSI.overbought" label="Overbought limit higher than"
              required></v-text-field>
          </v-col>
          <v-col cols="6" :sm="stateValues.general.rotationValue === 'e' ? 4 : 6">
            <v-text-field v-model="stateValues.stochasticRSI.backward" label="Backward limit for cross" required></v-text-field>
          </v-col>
        </v-row>
      </v-sheet>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>
<script>
import { reactive } from 'vue'
export default {
  name: 'App',
  props: {
    state: {
      type: Object
    }
  },
  setup(props) {
    const stateValues = reactive(props.state)

    return {
      stateValues
    }
  }
}
</script>
