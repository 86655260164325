<template>
    <v-expansion-panel>
        <v-expansion-panel-title>General</v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-sheet class="mx-auto">
              <v-row no-gutters>
                <v-col cols="6">
                  <v-select
                    v-model="stateValues.general.exchange"
                    :items="mainValues.exchanges"
                    item-title="name"
                    item-value="id"
                    label="Exchange"
                    multiple
                    required
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="stateValues.general.tradeType"
                    :items="mainValues.tradeTypes"
                    item-title="name"
                    item-value="id"
                    label="Trade Type"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="6" md="4">
                  <v-select
                    v-model="stateValues.general.rotationValue"
                    :items="mainValues.rotation"
                    item-title="name"
                    item-value="id"
                    label="Bull or Bear?"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="6" md="4">
                  <v-text-field
                    v-model="stateValues.general.priceChangeBull"
                    label="Max Pr. Ch. for Alert (BULL)"
                    :disabled="stateValues.general.rotationValue === 'n'"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                  <v-text-field
                    v-model="stateValues.general.priceChangeBear"
                    label="Min Pr. Ch. for Alert (BEAR)"
                    :disabled="stateValues.general.rotationValue === 'p'"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="stateValues.general.intervalValue"
                    :items="mainValues.interval"
                    item-title="name"
                    item-value="id"
                    label="Interval"
                    multiple
                    required
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="stateValues.general.minVolume"
                    label="Min Volume"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                  <v-select
                    v-model="stateValues.general.signals"
                    :items="mainValues.numberOfSignals"
                    item-title="name"
                    item-value="id"
                    label="Num of Indicator Signals for Highlight/Alert (Col)"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="6" md="3">
                  <v-select
                    v-model="stateValues.general.volumeSignal"
                    :items="mainValues.numberOfSignals"
                    item-title="name"
                    item-value="id"
                    label="Num of Volume Signals for Highlight/Alert (Col)"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="6" md="3">
                  <v-select
                    v-model="stateValues.general.signalsRow"
                    :items="mainValues.numberOfSignals"
                    item-title="name"
                    item-value="id"
                    label="Num of Indicator Signals for Alert (Row)"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="6" md="3">
                  <v-select
                    v-model="stateValues.general.signalsDivergenceRow"
                    :items="mainValues.numberOfSignals"
                    item-title="name"
                    item-value="id"
                    label="Num of Divergence Signals for Alert (Row)"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="6" md="3">
                      <v-checkbox
                        v-model="stateValues.general.signs"
                        :value="[1,2]"
                      >
                        <template v-slot:label>
                          <font-awesome-icon :icon="['far', 'circle-up']" />&nbsp;<font-awesome-icon :icon="['far', 'circle-down']" />&nbsp; Crosses
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-checkbox
                        v-model="stateValues.general.signs"
                        :value="[5,6]"
                      >
                      <template v-slot:label>
                        <font-awesome-icon :icon="['fas', 'arrow-trend-up']" />&nbsp;<font-awesome-icon :icon="['fas', 'arrow-trend-down']" />&nbsp; Potential Return
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-checkbox
                        v-model="stateValues.general.signs"
                        :value="[7,8]"
                      >
                        <template v-slot:label>
                          <font-awesome-icon :icon="['fas', 'arrow-up']" />&nbsp;<font-awesome-icon :icon="['fas', 'arrow-down']" />&nbsp; Return
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-checkbox
                        v-model="stateValues.general.signs"
                        :value="[3,4]"
                      >
                        <template v-slot:label>
                          <span class="round" style="background-color: green"></span>&nbsp;<span class="round" style="background-color: red"></span>&nbsp; Bullish/ Bearish
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4">
                  <v-switch v-model="stateValues.general.onlySelected" label="Only Selected Tokens" color="green"></v-switch>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    :disabled="!stateValues.general.onlySelected"
                    v-model="stateValues.general.tokens"
                    label="List Only Selected Tokens"
                    placeholder="Separate With Semicolon (BTCUSDT;ETHUSDT)"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-switch v-model="stateValues.general.notification" label="Show Notifications" color="green"></v-switch>
                </v-col>
                <v-col cols="6">
                  <v-switch v-model="stateValues.general.strict" label="Volume Priority Mode" color="green"></v-switch>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6">
                  <v-switch v-model="stateValues.general.repeat" label="Auto Update" color="green"></v-switch>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="stateValues.general.repeatInterval"
                      label="Update Interval"
                      required
                      :disabled="!stateValues.general.repeat"
                    ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters class="pb-4 pl-1 pr-1" v-if="stateValues.general.repeat">
                <v-col cols="6">
                  <div class="vcol">{{ mainValues.count }}</div>
                </v-col>
                <v-col cols="6">
                  <div class="vcol">{{ mainValues.time }}</div>
                </v-col>
              </v-row>
          </v-sheet>
        </v-expansion-panel-text>
      </v-expansion-panel>
</template>
<script>
import { reactive } from 'vue'
export default {
    name: 'App',
    props: {
        state: {
            type: Object
        },
        main: {
            type: Object
        }
    },
    setup(props) {
        const stateValues = reactive(props.state)
        const mainValues = reactive(props.main)

        return {
            stateValues,
            mainValues
        }
    }
}
</script>
