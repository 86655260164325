import { RSI } from 'technicalindicators'
import findStrongDivergence from './findStrongDivergence'

export default (state, obj) => {
  const inputRSI = {
    values : obj.close,
    period : state.divergence.period
  };

  const divergenceValue = findStrongDivergence(obj.close.slice(14), RSI.calculate(inputRSI), state)

  state.divergence.positive = divergenceValue.positive
  state.divergence.negative = divergenceValue.negative

  const divergence = state.divergence.positive === true ? 1 : state.divergence.negative === true ? 2 : 0
  const divergenceIndex = divergenceValue.priceIndex || ''

  return {
    divergence,
    divergenceIndex
  }
}