import { watch } from 'vue'
import { calculatePercent } from './helpers'

export const candlestickRealtime = (main, data, obj, inputs, exchangeData, intervalValue, callback) => {
    const socket = new WebSocket(`wss://stream.binance.com:9443/ws/${data.symbol.toLowerCase()}@kline_${intervalValue.v}`);

    socket.onopen = () => {
        console.log('WebSocket connection opened (Candlestick)');
    };

    let intervalFlag = null

    socket.onmessage = (event) => {
        const klineData = JSON.parse(event.data);
        const apiKlineArray = [
            klineData.k.t,   // Kline başlangıç zamanı
            klineData.k.o,   // Açılış fiyatı
            klineData.k.h,   // En yüksek fiyat
            klineData.k.l,   // En düşük fiyat
            klineData.k.c,   // Kapanış fiyatı
            klineData.k.v,   // İşlem hacmi
            klineData.k.T,   // Kline kapanış zamanı
            klineData.k.q,   // Quote varlık hacmi
            klineData.k.n,   // İşlem sayısı
            klineData.k.V,   // Taker buy base asset volume
            klineData.k.Q,   // Taker buy quote asset volume
            klineData.k.B    // Canlı olmayan veri için ignore
        ]

        if (intervalFlag !== null && intervalFlag !== klineData.k.t) {
            obj.open.push(+apiKlineArray[inputs[exchangeData.exchange].propertyNames.open])
            obj.open.unshift()
            obj.high.push(+apiKlineArray[inputs[exchangeData.exchange].propertyNames.high])
            obj.high.unshift()
            obj.low.push(+apiKlineArray[inputs[exchangeData.exchange].propertyNames.low])
            obj.low.unshift()
            obj.close.push(+apiKlineArray[inputs[exchangeData.exchange].propertyNames.close])
            obj.close.unshift()
            obj.volume.push(+apiKlineArray[inputs[exchangeData.exchange].propertyNames.volume])
            obj.volume.unshift()
            obj.hlc3.push(((+apiKlineArray[inputs[exchangeData.exchange].propertyNames.high]) + (+apiKlineArray[inputs[exchangeData.exchange].propertyNames.low]) + (+apiKlineArray[inputs[exchangeData.exchange].propertyNames.close])) / 3)
            obj.hlc3.unshift()
        } else {
            obj.open[obj.open.length - 1] = +apiKlineArray[inputs[exchangeData.exchange].propertyNames.open]
            obj.high[obj.high.length - 1] = +apiKlineArray[inputs[exchangeData.exchange].propertyNames.high]
            obj.low[obj.low.length - 1] = +apiKlineArray[inputs[exchangeData.exchange].propertyNames.low]
            obj.close[obj.close.length - 1] = +apiKlineArray[inputs[exchangeData.exchange].propertyNames.close]
            obj.volume[obj.volume.length - 1] = +apiKlineArray[inputs[exchangeData.exchange].propertyNames.volume]
            obj.hlc3[obj.hlc3.length - 1] = (((+apiKlineArray[inputs[exchangeData.exchange].propertyNames.high]) + (+apiKlineArray[inputs[exchangeData.exchange].propertyNames.low]) + (+apiKlineArray[inputs[exchangeData.exchange].propertyNames.close])) / 3)
        }

        intervalFlag = klineData.k.t

        callback()
        main.watchList = main.watchList.map((value) => {
            if (value) {
                if (value.symbol === data.symbol) {
                    return value = data
                } else {
                    return value
                }
            }
        })
    };

    socket.onclose = () => {
        console.log('WebSocket connection closed (Candlestick)');
    };

    socket.onerror = (error) => {
        console.error('WebSocket error (Candlestick):', error);
    };

    watch(() => main.tab, (value) => {
        if (value !== 4) {
            socket.close()
        }
    }, { immediate: true })
}

export const calculateBidAskRatio = (bids, asks, data) => {
    if (bids >= asks) {
        data.depthValues.percent = (calculatePercent(bids, asks) - 100).toFixed(2)
    } else {
        data.depthValues.percent = (-calculatePercent(asks, bids) - 100).toFixed(2)
    }
}

export const tickersRealtime = (main, data) => {
    const socket = new WebSocket(`wss://stream.binance.com:9443/ws/${data.symbol.toLowerCase()}@ticker`);

    socket.onopen = () => {
        console.log('WebSocket connection opened (Tickers)');
    };

    socket.onmessage = (event) => {
        const tickerData = JSON.parse(event.data);
        data.volume = parseInt(tickerData.q)
        data.priceChange = Number(tickerData.P).toFixed(2)
        data.lastPrice = tickerData.c
        calculateBidAskRatio(tickerData.B, tickerData.A, data)
    };

    socket.onclose = () => {
        console.log('WebSocket connection closed (Tickers)');
    };

    socket.onerror = (error) => {
        console.error('WebSocket error (Tickers):', error);
    };

    watch(() => main.tab, (value) => {
        if (value !== 4) {
            socket.close()
        }
    }, { immediate: true })
}

export const fundingRealtime = (main, data) => {
    const socket = new WebSocket(`wss://fstream.binance.com/ws/${data.symbol.toLowerCase()}@markPrice`);

    socket.onopen = () => {
        console.log('WebSocket connection opened (Funding Rate)');
    };

    socket.onmessage = (event) => {
        const fundingRateData = JSON.parse(event.data);
        data.funding = (parseFloat(fundingRateData.r) * 100).toFixed(4);
    };

    socket.onclose = () => {
        console.log('WebSocket connection closed (Funding Rate)');
    };

    socket.onerror = (error) => {
        console.error('WebSocket error (Funding Rate):', error);
    };

    watch(() => main.tab, (value) => {
        if (value !== 4) {
            socket.close()
        }
    }, { immediate: true })
}

export const depthRealtime = (data, calculateDepth) => {
    const socket = new WebSocket(`wss://stream.binance.com:9443/ws/${data.symbol.toLowerCase()}@depth`);

    socket.onopen = () => {
        console.log('WebSocket connection opened (Depth)');
    };

    socket.onmessage = (event) => {
        const depthData = JSON.parse(event.data);
        data.depth.bids = depthData.b
        data.depth.asks = depthData.a
        calculateDepth()
    };

    socket.onclose = () => {
        console.log('WebSocket connection closed (Depth)');
    };

    socket.onerror = (error) => {
        console.error('WebSocket error (Depth):', error);
    };
}