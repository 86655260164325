import { MACD } from 'technicalindicators'
import findSignal from './findSignal'

export default (state, obj) => {
  const macdInput = {
    values            : obj.close,
    fastPeriod        : state.macd.fastPeriod,
    slowPeriod        : state.macd.slowPeriod,
    signalPeriod      : state.macd.signalPeriod,
    SimpleMAOscillator: state.macd.SimpleMAOscillator,
    SimpleMASignal    : state.macd.SimpleMASignal
  }

  return findSignal(MACD.calculate(macdInput), state.macd)
}