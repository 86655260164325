import { SMA, EMA } from 'technicalindicators'
import findSignal from './findSignal'

export default (state, obj) => {
    const hlc3 = obj.hlc3.slice(state.wt.channel - 1)
      
    const EMAInput = {
      values : obj.hlc3,
      period : state.wt.channel
    };
    
    const esa = EMA.calculate(EMAInput)
    
    const valArr = esa.map((value, index) => {
      return Math.abs(hlc3[index] - value)
    })
    
    const EMAInputD = {
      values : valArr,
      period : state.wt.channel
    };
    
    const d = EMA.calculate(EMAInputD)
    
    const hlc3x = hlc3.slice(state.wt.channel - 1)
    
    const ci = esa.slice(state.wt.channel - 1).map((value, index) => {
      return (hlc3x[index] - value) / (0.015 * d[index])
    })
    
    const EMAInputTci = {
      values : ci,
      period : state.wt.average
    };
    
    const tci = EMA.calculate(EMAInputTci)
    
    const wt1 = tci
    
    const SMAInputTci = {
      values : wt1,
      period : 4
    };
    
    const wt2 = SMA.calculate(SMAInputTci)
    
    const wtResult = wt1.slice(3).map((item, index) => {
      return {
        w: item,
        t: wt2[index]
      }
    })

    return findSignal(wtResult, state.wt)
}