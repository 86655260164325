export default (indicator1, indicator2, context) => {
  
    let flag = null
    let result = null
  
    if (indicator1.length >= context.backward) {
  
      const SMAvalue = indicator1[indicator1.length - 1]
      const SMAPrice = indicator2[indicator2.length - 1]
  
      if (SMAPrice > SMAvalue) {
        flag = 1
        result = 3
        if (indicator2[indicator2.length - 2] > SMAPrice) {
          result = 6
        }
        if (indicator2[indicator2.length - 2] > SMAPrice && indicator2[indicator2.length - 3] > indicator2[indicator2.length - 2]) {
          result = 8
        }
      } else {
        flag = 2
        result = 4
        if (indicator2[indicator2.length - 2] < SMAPrice) {
          result = 5
        }
        if (indicator2[indicator2.length - 2] < SMAPrice && indicator2[indicator2.length - 3] < indicator2[indicator2.length - 2]) {
          result = 7
        }
      }
  
      if (flag === 1) {
        for (let i = 0; i < context.backward; i++) {
          const SMAval = indicator1[indicator1.length - (i + 1)]
          const SMAPr = indicator2[indicator2.length - (i + 1)]
          if (SMAPr < SMAval) {
            result = 1
            break
          }
        }
      }
  
      if (flag === 2) {
        for (let i = 0; i < context.backward; i++) {
          const SMAval = indicator1[indicator1.length - (i + 1)]
          const SMAPr = indicator2[indicator2.length - (i + 1)]
          if (SMAPr > SMAval) {
            result = 2
            break
          }
        }
      }
  
    }
  
    return result
  }