<template>
  <v-container fluid>
    <v-form fast-fail @submit.prevent>
      <v-expansion-panels>
        <general :state="state" :main="main" />
        <depth :state="state" />
        <volume :state="state" />
        <ma :state="state" />
        <st :state="state" />
        <rsidivergence :state="state" />
        <wt-cross :state="state" />
        <tsi :state="state" />
        <rsi :state="state" />
        <stochastic-rsi :state="state" />
        <stochastic-osc :state="state" />
        <kst :state="state" />
        <macd :state="state" />
    </v-expansion-panels>

      <div class="buttons">
        <v-btn type="button" :disabled="selectedSymbol.length > 0" v-show="!loading && localStorage.getItem('tokens')" color="default" @click="reset()" class="mt-2 mr-2">Reset</v-btn>
        <v-btn v-if="tab !== 4" type="button" :disabled="selectedSymbol.length > 0" v-show="matchedList.length > 0 && !loading" color="default" @click="update(null)" class="mt-2 mr-2">Update</v-btn>
        <v-btn type="submit" :disabled="!!loading || !(general.exchange.length > 0) || selectedSymbol.length > 0" color="success" @click="init" class="mt-2">{{ loading ? 'Processing... ' + loading + '%' : 'Start' }}</v-btn>
      </div>
    </v-form>
    <br>
    <v-card>
      <tabs :main="main" />
    </v-card>
    <table-main :state="state" :main="main" ref="tableComponent" />
  </v-container>
</template>
  
<script src="./MainPage.js"></script>
