<template>
  <v-expansion-panel>
    <v-expansion-panel-title>WT Cross</v-expansion-panel-title>
    <v-expansion-panel-text>
      <v-sheet class="mx-auto">
        <v-row no-gutters>
          <v-col cols="6">
            <v-text-field v-model="stateValues.wt.channel" label="Channel Length" required></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="stateValues.wt.average" label="Average Length" required></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select v-model="stateValues.wt.zeroLine" :items="[{
              name: 'False',
              id: false
            }, {
              name: 'True',
              id: true
            }]" item-title="name" item-value="id"
              :label="(stateValues.general.rotationValue === 'p' ? 'Below' : stateValues.general.rotationValue === 'n' ? 'Above' : 'Reference') + ' Zero Line'"
              required></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="stateValues.wt.backward" label="Backward limit for cross" required></v-text-field>
          </v-col>
        </v-row>
      </v-sheet>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>
<script>
import { reactive } from 'vue'
export default {
  name: 'App',
  props: {
    state: {
      type: Object
    }
  },
  setup(props) {
    const stateValues = reactive(props.state)

    return {
      stateValues
    }
  }
}
</script>
