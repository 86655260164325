<template>
  <v-tabs v-model="mainValues.tab" color="deep-purple-accent-4" align-tabs="center">
    <v-tab :value="1">Full List</v-tab>
    <v-tab :value="2">Highlights</v-tab>
    <v-tab :value="3">Alerts</v-tab>
    <v-tab :value="4">Realtime Watchlist</v-tab>
  </v-tabs>
</template>
<script>
import { reactive } from 'vue'
export default {
  name: 'App',
  props: {
    main: {
      type: Object
    }
  },
  setup(props) {
    let mainValues = reactive(props.main)

    return {
      mainValues
    }
  }
}
</script>
