<template>
 <main-comp></main-comp>
</template>

<script>
import mainComp from './layouts/MainPage.vue'

export default {
  name: 'App',
  components: {
    mainComp
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #dddddd;
}

.mx-auto {
  padding: 10px 10px 0 10px
}
fieldset {
  margin-bottom: 30px;
  text-align: center;
}
.table {
  font-size: 14px;
}

.search .v-input__details {
  display: none;
}

.table-depth .v-input__details {
  display: none;
}

@media only screen and (max-width: 600px) {
  .table {
    font-size: 12px;
  }
  .v-container {
    padding: 6px !important;
  }
}
.row-list {
  td {
    cursor: pointer;
  }
}
.first-row {
  cursor: pointer;
}
.table-list td.tlist, .table-list.rlist td {
  border-bottom: 2px solid #888888 !important;
}
.table tr th, .table tr td {
  padding: 0px 10px;
}
.table tr th {
  line-height: 12px;
}
.table tr td {
  white-space: nowrap;
}
.table-list .vol-ma span {
  display: inline-block;
  width: 4px;
  margin: 0 2px;
  border-radius: 2px;
  vertical-align: top;
}

.th-search {
  padding: 0 !important;
}
.vcol {
  padding: 10px;
  border: 1px solid #ddd
}
.highlight-red {
  background-color: rgb(210 67 67 / 8%)
}

.highlight-green {
  background-color: rgba(77, 210, 67, 0.08)
}
.buttons {
  text-align: center;
  margin-top: 15px;
}
.table .v-table__wrapper {
  position: relative;
  top: 15px;
  margin-bottom: 15px;
}
.v-input {
  padding: 0 5px 0 5px
}
.search.v-input {
  padding: 0;
}

.v-input {
  padding: 0 5px 0 5px
}

.v-progress-linear {
  margin: 2px 0
}
.round {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
}

.fa-arrow-trend-up, .fa-arrow-up, .fa-circle-up {
  color: green
}
.fa-arrow-trend-down, .fa-arrow-down, .fa-circle-down {
  color: red
}
.fa-circle-up, .fa-circle-down {
  font-size: 20px;
}

.v-expansion-panel-text__wrapper {
  padding: 0 !important;
}
.divergence-index {
  position: relative;
  top: -4px;
  left: 4px;
  font-size: 12px;
}
.volume-alert {
  white-space: nowrap;
  display: inline-block;
  padding: 3px;
  height: 80%;
  transform: scale(1, -1);
  border-top: 2px solid;
}
.table-depth .table-footer tr {
  background-color: white !important;
}
.table-depth tr {
  position: relative;
}
.table-depth {
  font-size: 13px;
}
.table-depth .v-input {
  display: inline-block;
}
.table-depth .v-label {
  font-size: 12px;
  font-weight: 700;
}
.add-list {
  cursor: pointer;
  > .icon {
    color: green
  }
  &:hover {
    color: green
  }
}
.remove-list {
  cursor: pointer;
  > .icon {
    color: red
  }
  &:hover {
    color: red;
  }
}

.ignore-update {
  cursor: pointer;
  pointer-events: none;
}
.text-purple {
  font-weight: bold
}
.bid {
  position: absolute; top: 0; right: 0; height: 100%; background-color: rgba(0, 128, 0, 0.1)
}
.ask {
  position: absolute; top: 0; left: 0; height: 100%; background-color: rgba(255, 0, 0, 0.1)
}
.depth-values {
  position: relative;
  span {
    display: inline-block
  }
}
</style>
