<template>
  <v-expansion-panel>
    <v-expansion-panel-title>Stochastic Oscillator</v-expansion-panel-title>
    <v-expansion-panel-text>
      <v-sheet class="mx-auto">
        <v-row no-gutters>
          <v-col cols="6" sm="3">
            <v-text-field v-model="stateValues.stochasticOsc.period" label="RSI Period" required></v-text-field>
          </v-col>
          <v-col cols="6" sm="3">
            <v-text-field v-model="stateValues.stochasticOsc.signal" label="Signal Period" required></v-text-field>
          </v-col>
          <v-col v-if="stateValues.general.rotationValue === 'e' || stateValues.general.rotationValue === 'p'" cols="6" sm="3">
            <v-text-field v-model="stateValues.stochasticOsc.oversold" label="Oversold limit less than" required></v-text-field>
          </v-col>
          <v-col v-if="stateValues.general.rotationValue === 'e' || stateValues.general.rotationValue === 'n'" cols="6" sm="3">
            <v-text-field v-model="stateValues.stochasticOsc.overbought" label="Overbought limit higher than"
              required></v-text-field>
          </v-col>
          <v-col cols="6" sm="3">
            <v-text-field v-model="stateValues.stochasticOsc.backward" label="Backward limit for cross" required></v-text-field>
          </v-col>
        </v-row>
      </v-sheet>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>
<script>
import { reactive } from 'vue'
export default {
  name: 'App',
  props: {
    state: {
      type: Object
    }
  },
  setup(props) {
    const stateValues = reactive(props.state)

    return {
      stateValues
    }
  }
}
</script>
