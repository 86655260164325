export default (indicator, context) => {

    let flag = null
    let result = null
  
    if (indicator.length >= context.backward) {
  
      const kLine = indicator[indicator.length - 1][context.keys[0]]
      const dLine = indicator[indicator.length - 1][context.keys[1]]
  
      if (kLine >= dLine) {
        flag = 1
        result = 3
        if (indicator[indicator.length - 2][context.keys[0]] > kLine) {
          result = 6
        }
        if (indicator[indicator.length - 2][context.keys[0]] > kLine && indicator[indicator.length - 3][context.keys[0]] > indicator[indicator.length - 2][context.keys[0]]) {
          result = 8
        }
      } else {
        flag = 2
        result = 4
        if (indicator[indicator.length - 2][context.keys[0]] < kLine) {
          result = 5
        }
        if (indicator[indicator.length - 2][context.keys[0]] < kLine && indicator[indicator.length - 3][context.keys[0]] < indicator[indicator.length - 2][context.keys[0]]) {
          result = 7
        }
      }
  
      if (flag === 1) {
        for (let i = 0; i < context.backward; i++) {
          const ind = indicator[indicator.length - (i + 1)]
          if (typeof ind !== 'undefined' && ind[context.keys[0]] <= ind[context.keys[1]] && (context.zeroLine ? (ind[context.keys[0]] <= context.oversold || ind[context.keys[1]] <= context.oversold) : true)) {
            result = 1
            break
          }
        }
      }
  
      if (flag === 2) {
        for (let i = 0; i < context.backward; i++) {
          const ind = indicator[indicator.length - (i + 1)]
          if (typeof ind !== 'undefined' && ind[context.keys[0]] >= ind[context.keys[1]] && (context.zeroLine ? (ind[context.keys[0]] >= context.overbought || ind[context.keys[1]] >= context.overbought) : true)) {
            result = 2
            break
          }
        }
      }
  
    }
  
    return result
  }