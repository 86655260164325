<template>
  <v-expansion-panel>
    <v-expansion-panel-title>Know Sure Thing</v-expansion-panel-title>
    <v-expansion-panel-text>
      <v-sheet class="mx-auto">
        <v-row no-gutters>
          <v-col cols="6" sm="3">
            <v-text-field v-model="stateValues.KST.ROCPer1" label="ROC Length 1" required></v-text-field>
          </v-col>
          <v-col cols="6" sm="3">
            <v-text-field v-model="stateValues.KST.ROCPer2" label="ROC Length 2" required></v-text-field>
          </v-col>
          <v-col cols="6" sm="3">
            <v-text-field v-model="stateValues.KST.ROCPer3" label="ROC Length 3" required></v-text-field>
          </v-col>
          <v-col cols="6" sm="3">
            <v-text-field v-model="stateValues.KST.ROCPer4" label="ROC Length 4" required></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" sm="3">
            <v-text-field v-model="stateValues.KST.SMAROCPer1" label="SMA Length 1" required></v-text-field>
          </v-col>
          <v-col cols="6" sm="3">
            <v-text-field v-model="stateValues.KST.SMAROCPer2" label="SMA Length 2" required></v-text-field>
          </v-col>
          <v-col cols="6" sm="3">
            <v-text-field v-model="stateValues.KST.SMAROCPer3" label="SMA Length 3" required></v-text-field>
          </v-col>
          <v-col cols="6" sm="3">
            <v-text-field v-model="stateValues.KST.SMAROCPer4" label="SMA Length 4" required></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" sm="4">
            <v-text-field v-model="stateValues.KST.signalPeriod" label="Signal Length" required></v-text-field>
          </v-col>
          <v-col cols="6" sm="4">
            <v-select v-model="stateValues.KST.zeroLine" :items="[{
              name: 'False',
              id: false
            }, {
              name: 'True',
              id: true
            }]" item-title="name" item-value="id"
              :label="(stateValues.general.rotationValue === 'p' ? 'Below' : stateValues.general.rotationValue === 'n' ? 'Above' : 'Reference') + ' Zero Line'"
              required></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field v-model="stateValues.KST.backward" label="Backward limit for cross" required></v-text-field>
          </v-col>
        </v-row>
      </v-sheet>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>
<script>
import { reactive } from 'vue'
export default {
  name: 'App',
  props: {
    state: {
      type: Object
    }
  },
  setup(props) {
    const stateValues = reactive(props.state)

    return {
      stateValues
    }
  }
}
</script>
