import { StochasticRSI } from 'technicalindicators'
import findSignal from './findSignal'

export default (state, obj) => {
  const inputStochasticRSI = {
    values : obj.close,
    rsiPeriod : state.stochasticRSI.period,
    stochasticPeriod : state.stochasticRSI.stochPeriod,
    kPeriod: state.stochasticRSI.kPeriod,
    dPeriod: state.stochasticRSI.dPeriod
  };

  return findSignal(StochasticRSI.calculate(inputStochasticRSI), state.stochasticRSI)
}