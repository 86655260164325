<template>
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-table class="table-depth" fixed-header fixed-footer height="80vh">
          <thead>
            <tr>
              <th colspan="4" class="text-center"><span class="pr-2"><strong>{{ symbol }}</strong> / {{ lastPrice }}</span></th>
            </tr>
            <tr>
              <th class="text-left"><strong>Bid</strong> ({{ data.depthValues?.bids?.filter((e) => e[0] !== 0).length }})</th>
              <th colspan="2" class="text-center" align="center">
                <v-switch
                  v-model="depthSortBy"
                  :true-value="1"
                  :false-value="0"
                  :label="`Sorted By ${depthSortBy === 0 ? 'Price' : 'Volume'}`"
                  color="green"
                ></v-switch>
              </th>
              <th class="text-right"><strong>Ask</strong> ({{ data.depthValues?.asks?.filter((e) => e[0] !== 0).length }})</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data.depthValues[type]" :key="index">
              <td colspan="2" style="position: relative">
                <div class="bid" style="" :style="{'width' : calculatePercent(data.depthValues.bids[index][1], maxDepthValue) + '%' }"></div>
                <div class="depth-values">
                  <span class="text-left" :class="{'text-purple' : (+data.depthValues.bids[index][0] > (mainValues.tab === 4 ? +mainValues.watchList.find((value) => value.symbol === symbol).lastPrice : +lastPrice)) }">{{ data.depthValues.bids[index][0] }}</span>
                  <span class="float-right text-green">{{ data.depthValues.bids[index][1]?.toLocaleString() }}</span>
                </div>
              </td>
              <td colspan="2" style="position: relative">
                <div class="ask" :style="{'width' : calculatePercent(data.depthValues.asks[index][1], maxDepthValue) + '%' }"></div>
                <div class="depth-values">
                  <span class="float-right" :class="{'text-purple' : (+data.depthValues.asks[index][0] < (mainValues.tab === 4 ? +mainValues.watchList.find((value) => value.symbol === symbol).lastPrice : +lastPrice)) }">{{ data.depthValues.asks[index][0] }}</span>
                  <span class="text-red">{{ data.depthValues.asks[index][1]?.toLocaleString() }}</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot class="table-footer">
            <tr>
              <th class="text-left"><strong>Total</strong></th>
              <th class="text-right text-green"><strong>{{ data.depthValues.reduceBids?.toLocaleString() }}</strong></th>
              <th class="text-left text-red"><strong>{{ data.depthValues.reduceAsks?.toLocaleString() }}</strong></th>
              <th class="text-left"></th>
            </tr>
          </tfoot>
        </v-table>
      </v-card>
    </v-dialog>
</template>
<script>
import { watch, reactive, toRefs, computed } from 'vue'
import axios from 'axios'
import depthInd from '../../methods/indicators/depth'
import { calculatePercent } from '../../methods/helpers'
export default {
    name: 'App',
    props: {
        depth: {
            type: Object
        },
        main: {
            type: Object
        }
    },
    setup(props, { expose }) {
        const state = reactive(props.depth)
        const mainValues = reactive(props.main)
        const sortedDepth = (item, value) => {
          item['asks'].sort((a, b) => value ? (b[value] - a[value]) : (a[value] - b[value]))
          item['bids'].sort((a, b) => b[value] - a[value])
        }

        watch(() => state.depthSortBy, (value) => {
          sortedDepth(state.data.depthValues, value)
        })

        const openDepth = (item) => {
            state.symbol = item.symbol
            state.exchange = item.exchange

            async function makeRequest() {
                const { bids, asks, reduceBids, reduceAsks } = await depthInd(state, mainValues.exc, item)
                const data = await axios.get(`${mainValues.exc[item.exchange.toLowerCase()].candlestick}?${mainValues.exc[item.exchange.toLowerCase()].propertyNames.symbol}=${item.symbol}&interval=1m&limit=1`)
                state.lastPrice = data.data[0][4]
                state.data.depthValues.reduceBids = reduceBids
                state.data.depthValues.reduceAsks = reduceAsks
                state.data.depthValues.bids = bids
                state.data.depthValues.asks = asks
                sortedDepth(state.data.depthValues, state.depthSortBy)
                if (bids.length > asks.length) {
                    state.type = 'bids'
                    state.data.depthValues.asks = [...asks, ...Array.from({ length: bids.length - asks.length }, () => [0, 0])]
                } else if (bids.length < asks.length) {
                    state.type = 'asks'
                    state.data.depthValues.bids = [...bids, ...Array.from({ length: asks.length - bids.length }, () => [0, 0])]
                }
                if (!state.dialog) state.dialog = true
            }

            const intervalTime = 4000;

            makeRequest()

            const intervalId = setInterval(makeRequest, intervalTime);

            const stopRequests = () => {
                clearInterval(intervalId);
            }

            watch(() => state.dialog, (value) => {
                if (!value) stopRequests();
            })
        }

        const maxDepthValue = computed(() => {
            const bids = state.data.depthValues.bids
            const asks = state.data.depthValues.asks

            const bidList = bids.map(row => row[1])
            const askList = asks.map(row => row[1])

            return Math.max(...bidList, ...askList)
        })

        expose({ openDepth })

        return {
            ...toRefs(state),
            calculatePercent,
            maxDepthValue,
            mainValues
        }
    }
}
</script>
