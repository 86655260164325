import { lowerCase, trim, flow, values } from 'lodash'
import { filter } from 'lodash/fp'

export const loading = (main, leng, curr) => {
    const val = Math.ceil(Math.abs(((curr * 100) / leng) - 100))
    main.loading = val === 100 ? '' : val
}

export const calculatePercent = function (val1 = 0, val2 = 0, percent = 100) {
    if (!val1 || !val2) return ''
    return ((val1 * percent) / val2).toFixed(2)
  }

  export const filterOptions = function (optionsArray, searchString, val = 'symbol') {
    if (!searchString) {
      return optionsArray
    }
  
    searchString = lowerCase(trim(searchString))
  
    return flow(
      values,
      filter(item => lowerCase(item[val]).indexOf(searchString) !== -1))(optionsArray)
  }