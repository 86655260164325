import { KST } from 'technicalindicators'
import findSignal from './findSignal'

export default (state, obj) => {
  const inputKST = {
    values      : obj.close,
    ROCPer1     : state.KST.ROCPer1,
    ROCPer2     : state.KST.ROCPer2,
    ROCPer3     : state.KST.ROCPer3,
    ROCPer4     : state.KST.ROCPer4,
    SMAROCPer1  : state.KST.SMAROCPer1,
    SMAROCPer2  : state.KST.SMAROCPer2,
    SMAROCPer3  : state.KST.SMAROCPer3,
    SMAROCPer4  : state.KST.SMAROCPer4,
    signalPeriod: state.KST.signalPeriod
  };
  
  return findSignal(KST.calculate(inputKST), state.KST)
}