export default (t, rotation) => {
    const alertValues = {
      body: ``,
      icon: './images/logoSmall.png',
      data: {
        symbol: t.symbol
      }
      }
  
    Notification.requestPermission().then(perm => {
      if (perm === 'granted') {
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification(`${t.symbol} - [${rotation}] - ${t.lastPrice} (${t.priceChange}%)`, alertValues);
          });
      }
    })
  }