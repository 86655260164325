import { reactive, toRefs, watch, ref } from 'vue'
import { clone } from 'lodash'
import { exchanges, exchangesRealtime } from '../methods/index' // eslint-disable-line
import general from '../components/filters/general.vue'
import depth from '../components/filters/depth.vue'
import volume from '../components/filters/volume.vue'
import rsidivergence from '../components/filters/rsidivergence.vue'
import st from '../components/filters/supertrend.vue'
import ma from '../components/filters/MA.vue'
import wtCross from '../components/filters/wtCross.vue'
import tsi from '../components/filters/tsi.vue'
import rsi from '../components/filters/rsi.vue'
import stochasticRsi from '../components/filters/stochasticRsi.vue'
import stochasticOsc from '../components/filters/stochasticOsc.vue'
import kst from '../components/filters/kst.vue'
import macd from '../components/filters/macd.vue'
import tabs from '../components/table/tabs.vue'
import tableMain from '../components/table/table.vue'

export default {
  name: 'Main-Page',
  components: {
    general,
    depth,
    volume,
    rsidivergence,
    st,
    ma,
    wtCross,
    tsi,
    rsi,
    stochasticRsi,
    stochasticOsc,
    kst,
    macd,
    tabs,
    tableMain
  },
  setup () {
    const state = reactive(JSON.parse(localStorage.getItem('tokens')) || {
      general: {
        onlySelected: false,
        tokens: '',
        priceChangeBull: 5,
        priceChangeBear: '',
        volumeSignal: 3,
        minVolume: 10000000,
        exchange: ['binance'],
        tradeType: 'spot',
        intervalValue: [{
          v: '5m',
          m: 5,
          order: 2
        },{
          v: '15m',
          m: 15,
          order: 3
        },{
          v: '1h',
          m: 60,
          order: 6
        },
        {
          v: '4h',
          m: 240,
          order: 9
        },
        {
          v: '1d',
          m: 'D',
          order: 10
        },
        {
          v: '1w',
          m: 'W',
          order: 11
        }],
        rotationValue: 'e',
        repeat: false,
        repeatInterval: 30,
        signals: 4,
        signalsRow: 3,
        signalsDivergenceRow: 1,
        highlight: false,
        notification: false,
        strict: false,
        signs: [[1,2], [5,6], [7,8]]
      },
      depth: {
        dialog: false,
        type: 'bids',
        symbol: null,
        exchange: null,
        lastPrice: null,
        data: {
          depthValues: {}
        },
        open: true,
        limit: 5000,
        threshold: 0.2,
        loading: false,
        depthSortBy: 1
      },
      volume: {
        period: 20,
        backward: 9,
        backwardItems: [1,2,3,4,5,6,7,8,9,10]
      },
      wt: {
        channel: 10,
        average: 21,
        zeroLine: false,
        backward: 3,
        oversold: 0,
        overbought: 0,
        keys: ['w', 't'],
        positive: false,
        negative: false
      },
      tsi: {
        long: 25,
        short: 13,
        signal: 13,
        zeroLine: false,
        backward: 3,
        oversold: 0,
        overbought: 0,
        keys: ['tsi', 'signal'],
        positive: false,
        negative: false
      },
      divergence: {
        period: 14,
        threshold: 2,
        comparison: 60,
        tolerance: 2,
        oversold: 100,
        overbought: 0,
        positive: false,
        negative: false
      },
      MA: {
        period1: 50,
        period2: 200,
        backward: 10,
        typeData: ['SMA', 'EMA'],
        type: 'SMA',
        positive: false,
        negative: false
      },
      rsi: {
        period: 14,
        backward: 3,
        typeData: ['SMA', 'EMA'],
        type: 'SMA',
        zeroLine: false,
        oversold: 0,
        overbought: 0,
        keys: ['rsi', 'signal'],
        positive: false,
        negative: false
      },
      stochasticRSI: {
        period: 14,
        stochPeriod: 14,
        oversold: 100,
        overbought: 0,
        kPeriod: 3,
        dPeriod: 3,
        backward: 3,
        zeroLine: true,
        keys: ['k', 'd'],
        positive: false,
        negative: false
      },
      stochasticOsc: {
        period: 14,
        oversold: 100,
        overbought: 0,
        signal: 3,
        backward: 3,
        zeroLine: true,
        keys: ['k', 'd'],
        positive: false,
        negative: false
      },
      KST: {
        ROCPer1     : 10,
        ROCPer2     : 15,
        ROCPer3     : 20,
        ROCPer4     : 30,
        SMAROCPer1  : 10,
        SMAROCPer2  : 10,
        SMAROCPer3  : 10,
        SMAROCPer4  : 15,
        signalPeriod: 9,
        backward: 3,
        oversold: 0,
        overbought: 0,
        zeroLine: false,
        keys: ['kst', 'signal'],
        positive: false,
        negative: false
      },
      macd: {
        fastPeriod: 12,
        slowPeriod: 26,
        signalPeriod: 9,
        SimpleMAOscillator: false,
        SimpleMASignal: false,
        backward: 3,
        oversold: 0,
        overbought: 0,
        zeroLine: false,
        keys: ['MACD', 'signal'],
        positive: false,
        negative: false
      },
      st: {
        period: 10,
        factor: 3,
        threshold: 5
      }
    })

    const main = reactive({
      exchanges: [{
        name: 'Binance',
        id: 'binance'
      },
      {
        name: 'Gate.io',
        id: 'gate'
      }],
      tradeTypes: [{
          name: 'Spot',
          id: 'spot'
        },{
          name: 'Futures',
          id: 'futures'
      }],
      numberOfSignals: [
          1,2,3,4,5,6,7
      ],
      interval: [
          {
            name: '1 min',
            id: {
              v: '1m',
              m: 1,
              order: 0
            }
          },
          {
            name: '3 min',
            id: {
              v: '3m',
              m: 3,
              order: 1
            }
          },
          {
            name: '5 min',
            id: {
              v: '5m',
              m: 5,
              order: 2
            }
          },
          {
            name: '15 min',
            id: {
              v: '15m',
              m: 15,
              order: 3
            }
          },
          {
            name: '30 min',
            id: {
              v: '30m',
              m: 30,
              order: 4
            }
          },
          {
            name: '1 hour',
            id: {
              v: '1h',
              m: 60,
              order: 6
            }
          },
          {
            name: '2 hours',
            id: {
              v: '2h',
              m: 120,
              order: 7
            }
          },
          {
            name: '3 hours',
            id: {
              v: '3h',
              m: 180,
              order: 8
            }
          },
          {
            name: '4 hours',
            id: {
              v: '4h',
              m: 240,
              order: 9
            }
          },
          {
            name: '1 day',
            id: {
              v: '1d',
              m: 'D',
              order: 10
            }
          },
          {
            name: '1 week',
            id: {
              v: '1w',
              m: 'W',
              order: 11
            }
          }
      ],
      rotation: [
        {
            name: 'Either',
            id: 'e'
          },
          {
            name: 'Bull (Positive)',
            id: 'p'
          },
          {
            name: 'Bear (Negative)',
            id: 'n'
          }
      ],
      updateData: null,
      tab: 1,
      selectedSymbol: [],
      searchString: '',
      counter: 0,
      count: 0,
      timer: 0,
      clock: 0,
      loading: null,
      time: '0 : 0 : 0',
      matchedList: [],
      highlightData: [],
      coinlist: [],
      alertList: [],
      watchList: [],
      sortedList: undefined,
      sortedText: '',
      lastPrice: null,
      exc: {
          binance: {
            exchange: 'Binance',
            tickers: state.general.tradeType === 'futures' ? 'https://fapi.binance.com/fapi/v1/ticker/24hr' : 'https://api.binance.com/api/v3/ticker/24hr',
            candlestick: state.general.tradeType === 'futures' ? 'https://fapi.binance.com/fapi/v1/klines' : 'https://api.binance.com/api/v3/klines',
            funding: 'https://fapi.binance.com/fapi/v1/fundingRate',
            depth: state.general.tradeType === 'futures' ? 'https://fapi.binance.com/fapi/v1/depth' : 'https://api.binance.com/api/v3/depth',
            market: 'USDT',
            propertyNames: {
              symbol: 'symbol',
              quoteVolume: 'quoteVolume',
              lastPrice: 'lastPrice',
              priceChangePercent: 'priceChangePercent',
              open: 1,
              high: 2,
              low: 3,
              close: 4,
              volume: 5
            }
          },
          gate: {
            exchange: 'Gate.io',
            tickers: 'https://c-finder.ugurmurat.com.tr/gate-tickers.php',
            candlestick: 'https://c-finder.ugurmurat.com.tr/gate-candlesticks.php',
            depth: 'https://c-finder.ugurmurat.com.tr/gate-orderbook.php',
            market: 'USDT',
            propertyNames: {
              symbol: 'currency_pair',
              quoteVolume: 'quote_volume',
              lastPrice: 'last',
              priceChangePercent: 'change_percentage',
              open: 5,
              high: 3,
              low: 4,
              close: 2,
              volume: 1
            }
          }
      }
    })

    main.watchList = JSON.parse(localStorage.getItem('watchList')) || []

    main.count = clone(state.general.repeatInterval)

    const setTabData = (value) => {
      if (value === 1) {
        main.coinlist = main.matchedList
      } else if (value === 2) {
        main.coinlist = main.highlightData
      } else if (value === 3) {
        main.coinlist = main.alertList
      } else {
        main.coinlist = main.watchList
      }
    }


    const updateWatchList = (data) => {
      main.watchList = main.watchList.map((value) => {
        if (value) {
          if (value.symbol === data.symbol) {
            return value = data
          } else {
            return value
          }
        }
      })
    }

    const init = () => {
      localStorage.removeItem('tokens')
      localStorage.setItem('tokens', JSON.stringify(state))
      main.tab = 1
      main.matchedList = []
      main.alertList = []
      main.highlightData = []
      main.coinlist = []
      main.count = clone(state.general.repeatInterval)
      main.selectedSymbol = []
      main.loading = '0'
      main.sortedList = undefined,
      main.sortedText = ''
      const getDivergences = (state) => {
        exchanges(state, main, null, (data) => {
          main.matchedList.push(data)
          if (data.highlight) {
            main.highlightData.push(data)
          }
          if (data.alert) {
            main.alertList.push(data)
          }

          updateWatchList(data)

          setTabData(main.tab)
        }, (data) => {
          if (data) {
            const matchedListFind = main.matchedList.find((value) => {
              return value.symbol === data.symbol
            })
            if (!matchedListFind) main.matchedList.push(data)

            main.matchedList = main.matchedList.map((value) => {
              if (value) {
                if (value.symbol === data.symbol) {
                  return value = data
                } else {
                  return value
                }
              }
            })

            state.depth.data = data
            
            if (data.highlight) {
              const highlightDataFind = main.highlightData.find((value) => {
                return value.symbol === data.symbol
                })
                if (!highlightDataFind) main.highlightData.push(data)

              main.highlightData = main.highlightData.map((value) => {
                if (value) {
                  if (value.symbol === data.symbol) {
                    return value = data
                  } else {
                    return value
                  }
                }
              })
            }

            if (data.alert) {
              const alertDataFind = main.alertList.find((value) => {
                return value.symbol === data.symbol
              })
              if (!alertDataFind) main.alertList.push(data)

              main.alertList = main.alertList.map((value) => {
                if (value) {
                  if (value.symbol === data.symbol) {
                    return value = data
                  } else {
                    return value
                  }
                }
              })
            }

            updateWatchList(data)

            main.highlightData = main.highlightData.filter((value) => {
              return !(value.symbol === data.symbol && (!!value.highlight !== !!data.highlight)) 
            })

            main.alertList = main.alertList.filter((value) => {
              return !(value.symbol === data.symbol && value.alert !== data.alert)
            })
            setTabData(main.tab)
          }
          main.selectedSymbol.splice(main.selectedSymbol.indexOf(data.symbol), 1)
        })
      }

      getDivergences(state)

      clearInterval(main.timer)
      main.timer = setInterval(() => {
        main.time = (Math.floor((main.clock/60)/60))+" : "+Math.floor(main.clock/60)+" : "+main.clock%60
        main.clock++
      }, 1000)

      watch(() => main.loading, (value) => {
        main.count = clone(state.general.repeatInterval)
        clearInterval(main.counter);
        if (state.general.repeat && value === '') {
          main.counter = setInterval(tick, 1000);
        }
      })
    }

    watch(() => main.tab, (value) => {
      if (value) setTabData(value)

      if (value === 4) {
        exchangesRealtime(state, main, null, (data) => {
          updateWatchList(data)
        })
      }
    }, { immediate: true })

    const tableComponent = ref(null);

    function tick () {
      if (main.count == 0) {
        tableComponent.value.update()
      }
      main.count--
    }

    const update = (value) => {
      tableComponent.value.update(value)
    }

    const openUrl = (url) => {
      window.open(url, '_blank')
    }

    const reset = () => {
      localStorage.removeItem('tokens')
      location.reload(true)
    }

    return {
      ...toRefs(state),
      ...toRefs(main),
      init,
      openUrl,
      localStorage,
      reset,
      state,
      main,
      tableComponent,
      update
    }
  }
}