import { calculatePercent } from "../helpers"

export default (ind, context) => {

    const result = {
      ma: [],
      volume: [],
      rotation: [],
      signal: [],
      signalValue: [],
      percent: []
    }
  
    if (ind.volume.length >= context.backward) {
      let rotation = []
        for (let k = 0; k < context.backward; k++) {
          let vol = null
          for (let i = 0; i < context.period; i++) {
            const volume = ind.volume[ind.volume.length - (i + (k + 1))]
            const close = ind.close[ind.close.length - (i + (k + 1))]
            const closeBefore = ind.close[ind.close.length - (i + (k + 2))]
            vol += volume
            rotation.unshift(close > closeBefore)
          }
          result.ma.unshift(vol / context.period)
        }
        result.volume = ind.volume.slice(-context.backward)
        const volume = ind.volume.slice(-context.period)
        result.rotation = rotation.slice(-context.backward)
        for (let i = 0; i < result.rotation.length; i++) {
          const pos = result.volume[i] >= result.ma[i]
          const maxVolume = calculatePercent(result.volume[i], Math.max(...volume))
          result.percent.push(maxVolume > 100 ? 100 : maxVolume)
          if (pos) {
            result.signal.push(result.rotation[i] ? 'green' : 'red')
            result.signalValue.push(result.rotation[i] ? 'bull' : 'bear')
          } else {
            result.signal.push(result.rotation[i] ? 'rgb(0 128 0 / 35%)' : 'rgb(255 0 0 / 35%)')
            result.signalValue.push(result.rotation[i] ? 'positive' : 'negative')
          }
        }
    }
  
    return result
  }