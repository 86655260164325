import { Stochastic } from 'technicalindicators'
import findSignal from './findSignal'

export default (state, obj) => {
  const inputStochastic = {
    high: obj.high,
    low: obj.low,
    close: obj.close,
    period: state.stochasticOsc.period,
    signalPeriod: state.stochasticOsc.signal
  }

  return findSignal(Stochastic.calculate(inputStochastic), state.stochasticOsc)
}