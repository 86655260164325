import axios from 'axios'
import { filter, orderBy, intersectionBy } from 'lodash'
import { loading } from './helpers'
import pushNotification from './pushNotification'
import { indicators, alerts } from './indicators/index'
import { tickersRealtime, candlestickRealtime, fundingRealtime, calculateBidAskRatio } from './realtime'

export const exchanges = async (state, main, updateSymbol, callback, callback2) => {

  const updateData = async function (state, updateSymbol, callback) {

    let exchangeData = []

    const intervalValue = orderBy(state.general.intervalValue, 'order', 'asc')

    for (let i = 0; i < state.general.exchange.length; i++) {
      try {
        const data = await axios.get(main.exc[state.general.exchange[i]].tickers)
        const excData = filter(data.data, function (o) {
          const symbol = o[main.exc[state.general.exchange[i]].propertyNames.symbol].replace('_', '')
          let result = false
          if (updateSymbol) {
            result = (updateSymbol === symbol)
          } else {
            result = symbol.endsWith('USDT') && ((state.general.tokens && state.general.onlySelected) ? state.general.tokens.includes(symbol) : true)
          }
          return result
        })
        const exxc = excData.map((a) => {
          a.exchange = state.general.exchange[i]
          return a
        })
        exchangeData.push(...exxc)
      } catch (err) {
        console.log('Error! ', err)
      }
    }

    let count = exchangeData.length

    for (let i = 0; i < exchangeData.length; i++) {

      try {
        const alertArr = []
        const alertVolumeArr = []
        const data = {
          exchange: main.exc[exchangeData[i].exchange].exchange,
          symbol: exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.symbol].replace('_', ''),
          lastPrice: exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.lastPrice],
          priceChange: Number(exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.priceChangePercent]).toFixed(2),
          volume: parseInt(exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.quoteVolume]),
          interval: null,
          candlestick: [],
          highlight: null,
          alert: false,
          funding: '',
          depth: {},
          divergenceAlert: [],
          depthValues: {
            percent: null
          }
        }
        if ((Number(exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.quoteVolume]) > state.general.minVolume)) {
          const priceChange = Number(exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.priceChangePercent]).toFixed(0)

          if (state.general.tradeType === 'futures' && data.exchange === 'Binance') {
            const exchangeFundingData = await axios.get(`${main.exc[exchangeData[i].exchange].funding}?${main.exc[exchangeData[i].exchange].propertyNames.symbol}=${exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.symbol]}&limit=1`)
            data.funding = (parseFloat(exchangeFundingData?.data[0].fundingRate) * 100).toFixed(4);
          }

          calculateBidAskRatio(exchangeData[i].bidQty, exchangeData[i].askQty, data)

          /*

          if (state.depth.open) {
            const exchangeOrderDepth = await axios.get(`${main.exc[exchangeData[i].exchange].depth}?${main.exc[exchangeData[i].exchange].propertyNames.symbol}=${exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.symbol]}&limit=${state.depth.limit}`)
            data.depth = exchangeOrderDepth.data

            const calculateDepth = () => {
              data.depthValues.reduceBids = null
              data.depthValues.reduceAsks = null
              data.depthValues.percent = null

              data.depthValues.reduceBids = reduce(data.depth.bids, function (sum, n) {
                return sum + (+n[1]);
              }, 0)
              data.depthValues.reduceAsks = reduce(data.depth.asks, function (sum, n) {
                return sum + (+n[1]);
              }, 0)

              if (data.depth.bids.length >= data.depth.asks.length) {
                data.depthValues.percent = (calculatePercent(data.depthValues.reduceBids, data.depthValues.reduceAsks) - 100).toFixed(2)
              } else {
                data.depthValues.percent = (-calculatePercent(data.depthValues.reduceAsks, data.depthValues.reduceBids) - 100).toFixed(2)
              }
            }

            calculateDepth()
          }
            */
          
          for (let j = 0; j < intervalValue.length; j++) {
            const calculateIndicators = (exchangeCandleStickData) => {
              const obj = {
                prices: [],
                close: [],
                high: [],
                low: [],
                open: [],
                volume: [],
                hlc3: [],
                interval: null,
                ma: null,
                divergence: null,
                divergenceIndex: null,
                stochasticRSI: null,
                stochasticOsc: null,
                kst: null,
                macd: null,
                wt: null,
                tsi: null,
                rsi: null,
                highlight: null,
                volumeHighlight: null,
                alert: null,
                symbol: exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.symbol],
                lastPrice: exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.lastPrice],
                url: `https://www.tradingview.com/chart/?symbol=${exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.symbol].replace('_', '')}&interval=${(intervalValue[j].m)}`
              }
              obj.interval = intervalValue[j].v
              for (let j = 0; j < exchangeCandleStickData.data.length; j++) {
                obj.open.push(+exchangeCandleStickData.data[j][main.exc[exchangeData[i].exchange].propertyNames.open])
                obj.high.push(+exchangeCandleStickData.data[j][main.exc[exchangeData[i].exchange].propertyNames.high])
                obj.low.push(+exchangeCandleStickData.data[j][main.exc[exchangeData[i].exchange].propertyNames.low])
                obj.close.push(+exchangeCandleStickData.data[j][main.exc[exchangeData[i].exchange].propertyNames.close])
                obj.volume.push(+exchangeCandleStickData.data[j][main.exc[exchangeData[i].exchange].propertyNames.volume])
                obj.hlc3.push(((+exchangeCandleStickData.data[j][main.exc[exchangeData[i].exchange].propertyNames.high]) + (+exchangeCandleStickData.data[j][main.exc[exchangeData[i].exchange].propertyNames.low]) + (+exchangeCandleStickData.data[j][main.exc[exchangeData[i].exchange].propertyNames.close])) / 3)
              }

              indicators(state, data, obj, alertArr, alertVolumeArr, j)

            }

            const exchangeCandleStickData = await axios.get(`${main.exc[exchangeData[i].exchange].candlestick}?${main.exc[exchangeData[i].exchange].propertyNames.symbol}=${exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.symbol]}&interval=${intervalValue[j].v}&limit=${state.MA.period2 < 50 ? 60 : +state.MA.period2 + 10}`)

            calculateIndicators(exchangeCandleStickData)
          }
          alerts(state, data, alertArr, alertVolumeArr, priceChange, pushNotification)
          callback(data)
        }
        if (!updateSymbol) {
          count--
          loading(main, exchangeData.length, count)
        }
      } catch (err) {
        console.log('Error! ', err)
      }
    }
  }

  updateData(state, updateSymbol, (values) => {
    callback(values)
  })

  main.updateData = (symbol) => {
    updateData(state, symbol, (values) => {
      callback2(values)
    })
  }
}

export const exchangesRealtime = async (state, main, updateSymbol, callback) => {

  const updateData = async function (state, updateSymbol, callback) {

    let exchangeData = []

    const intervalValue = orderBy(state.general.intervalValue, 'order', 'asc')

    for (let i = 0; i < state.general.exchange.length; i++) {
      try {
        const data = await axios.get(main.exc[state.general.exchange[i]].tickers)
        const excData = filter(data.data, function (o) {
          const symbol = o[main.exc[state.general.exchange[i]].propertyNames.symbol].replace('_', '')
          let result = false
          if (updateSymbol) {
            result = (updateSymbol === symbol)
          } else {
            result = symbol.endsWith('USDT') && ((state.general.tokens && state.general.onlySelected) ? state.general.tokens.includes(symbol) : true)
          }
          return result
        })
        const exxc = excData.map((a) => {
          a.exchange = state.general.exchange[i]
          return a
        })
        exchangeData.push(...exxc)
      } catch (err) {
        console.log('Error! ', err)
      }
    }

    exchangeData = intersectionBy(exchangeData, main.watchList, 'symbol')

    for (let i = 0; i < exchangeData.length; i++) {

      try {
        const alertArr = []
        const alertVolumeArr = []
        const data = {
          exchange: main.exc[exchangeData[i].exchange].exchange,
          symbol: exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.symbol].replace('_', ''),
          lastPrice: exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.lastPrice],
          priceChange: Number(exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.priceChangePercent]).toFixed(2),
          volume: parseInt(exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.quoteVolume]),
          interval: null,
          candlestick: [],
          highlight: null,
          alert: false,
          funding: '',
          depth: {},
          divergenceAlert: [],
          depthValues: {
            percent: null
          }
        }
        if ((Number(exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.quoteVolume]) > state.general.minVolume)) {
          const priceChange = Number(exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.priceChangePercent]).toFixed(0)

          if (state.general.tradeType === 'futures' && data.exchange === 'Binance') {
            fundingRealtime(main, data)
          }

          /*
          if (state.depth.open) {
            const exchangeOrderDepth = await axios.get(`${main.exc[exchangeData[i].exchange].depth}?${main.exc[exchangeData[i].exchange].propertyNames.symbol}=${exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.symbol]}&limit=${state.depth.limit}`)
            data.depth = exchangeOrderDepth.data

            const calculateDepth = () => {
              data.depthValues.reduceBids = null
              data.depthValues.reduceAsks = null
              data.depthValues.percent = null

              data.depthValues.reduceBids = reduce(data.depth.bids, function (sum, n) {
                return sum + (+n[1]);
              }, 0)
              data.depthValues.reduceAsks = reduce(data.depth.asks, function (sum, n) {
                return sum + (+n[1]);
              }, 0)

              if (data.depth.bids.length >= data.depth.asks.length) {
                data.depthValues.percent = (calculatePercent(data.depthValues.reduceBids, data.depthValues.reduceAsks) - 100).toFixed(2)
              } else {
                data.depthValues.percent = (-calculatePercent(data.depthValues.reduceAsks, data.depthValues.reduceBids) - 100).toFixed(2)
              }
            }

            depthRealtime(data, calculateDepth)
          }
          */

          tickersRealtime(main, data)

          for (let j = 0; j < intervalValue.length; j++) {
            const calculateIndicators = (exchangeCandleStickData) => {
              const obj = {
                prices: [],
                close: [],
                high: [],
                low: [],
                open: [],
                volume: [],
                hlc3: [],
                interval: null,
                ma: null,
                divergence: null,
                divergenceIndex: null,
                stochasticRSI: null,
                stochasticOsc: null,
                kst: null,
                macd: null,
                wt: null,
                tsi: null,
                rsi: null,
                st: null,
                highlight: null,
                volumeHighlight: null,
                alert: null,
                symbol: exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.symbol],
                lastPrice: exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.lastPrice],
                url: `https://www.tradingview.com/chart/?symbol=${exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.symbol].replace('_', '')}&interval=${(intervalValue[j].m)}`
              }
              obj.interval = intervalValue[j].v
              for (let j = 0; j < exchangeCandleStickData.data.length; j++) {
                obj.open.push(+exchangeCandleStickData.data[j][main.exc[exchangeData[i].exchange].propertyNames.open])
                obj.high.push(+exchangeCandleStickData.data[j][main.exc[exchangeData[i].exchange].propertyNames.high])
                obj.low.push(+exchangeCandleStickData.data[j][main.exc[exchangeData[i].exchange].propertyNames.low])
                obj.close.push(+exchangeCandleStickData.data[j][main.exc[exchangeData[i].exchange].propertyNames.close])
                obj.volume.push(+exchangeCandleStickData.data[j][main.exc[exchangeData[i].exchange].propertyNames.volume])
                obj.hlc3.push(((+exchangeCandleStickData.data[j][main.exc[exchangeData[i].exchange].propertyNames.high]) + (+exchangeCandleStickData.data[j][main.exc[exchangeData[i].exchange].propertyNames.low]) + (+exchangeCandleStickData.data[j][main.exc[exchangeData[i].exchange].propertyNames.close])) / 3)
              }

              indicators(state, data, obj, alertArr, alertVolumeArr, j)

              candlestickRealtime(main, data, obj, main.exc, exchangeData[i], intervalValue[j], () => {
                indicators(state, data, obj, alertArr, alertVolumeArr, j)
              })

            }

            const exchangeCandleStickData = await axios.get(`${main.exc[exchangeData[i].exchange].candlestick}?${main.exc[exchangeData[i].exchange].propertyNames.symbol}=${exchangeData[i][main.exc[exchangeData[i].exchange].propertyNames.symbol]}&interval=${intervalValue[j].v}&limit=${state.MA.period2 < 50 ? 60 : +state.MA.period2 + 10}`)

            calculateIndicators(exchangeCandleStickData)
          }
          alerts(state, data, alertArr, alertVolumeArr, priceChange, pushNotification)
          callback(data)
        }
      } catch (err) {
        console.log('Error! ', err)
      }
    }
  }

  updateData(state, updateSymbol, (values) => {
    callback(values)
  })
}