import { EMA } from 'technicalindicators'
import findSignal from './findSignal'

export default (state, obj) => {
   // TSI
   const tsiInputs = {
     close: obj.close,
     long: state.tsi.long,
     short: state.tsi.short,
     signal: state.tsi.signal
   }
   
   const tsi = function (data) {
     let mom = []
     let abs = []
     let ts = []
     let tsi = []
     for(let i = 1; i < data.close.length; i++) {
       mom.push(data.close[i] - data.close[i - 1]);
       abs.push(Math.abs(data.close[i] - data.close[i - 1]));
     }
     let sma1 = EMA.calculate({ values: mom, period: data.long }),
         sma2 = EMA.calculate({ values: abs, period: data.long }),
         ema1 = EMA.calculate({ values: sma1, period: data.short }),
         ema2 = EMA.calculate({ values: sma2, period: data.short });
     for(let i = 0; i < ema1.length; i++) {
       ts.push(ema1[i] / ema2[i]);
     }
     let tma = EMA.calculate({ values: ts, period: data.signal });
     ts.splice(0, ts.length - tma.length)
     for(let i = 0; i < tma.length; i++) {
       tsi.push({ signal: tma[i], tsi: ts[i] });
     }
     return tsi;
   }

   return findSignal(tsi(tsiInputs), state.tsi)
}