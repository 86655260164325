import { createApp } from 'vue'
import App from './App.vue'

// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faArrowTrendUp, faArrowTrendDown, faArrowUp, faArrowDown, faSpinner, faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons'
import { faCircleUp, faCircleDown } from '@fortawesome/free-regular-svg-icons'

library.add(faArrowTrendUp)
library.add(faArrowTrendDown)
library.add(faArrowUp)
library.add(faArrowDown)
library.add(faCircleUp)
library.add(faCircleDown)
library.add(faSpinner)
library.add(faCirclePlus)
library.add(faCircleMinus)

const vuetify = createVuetify({
  components,
  directives,
})

createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(vuetify).mount('#app')
