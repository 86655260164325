import rsiDivergence from './rsiDivergence'
import tsi from './tsi'
import wtCross from './wtcross'
import rsi from './rsi'
import ma from './ma'
import stochasticRsi from './stochasticRsi'
import stochasticOsc from './stochasticOsc'
import kst from './kst'
import macd from './macd'
import volume from './volume'
import supertrend from './supertrend'

export const indicators = (state, data, obj, alertArr, alertVolumeArr, j) => {
  // TSI
  obj.tsi = tsi(state, obj)

  // WAVETREND CROSS
  obj.wt = wtCross(state, obj)

  // EMA - SMA
  obj.ma = ma(state, obj)

  // DIVERGENCE
  const { divergence, divergenceIndex } = rsiDivergence(state, obj)
  obj.divergence = divergence
  obj.divergenceIndex = divergenceIndex

  // RSI
  obj.rsi = rsi(state, obj)

  // STOCHASTIC RSI
  obj.stochasticRSI = stochasticRsi(state, obj)

  // STOCHASTIC OSCILLATOR
  obj.stochasticOsc = stochasticOsc(state, obj)

  // KST
  obj.kst = kst(state, obj)

  // MACD
  obj.macd = macd(state, obj)

  // VOLUME
  obj.volumeSignal = volume(state, obj)

  // SUPERTREND
  obj.st = supertrend(state, obj)

  const alertfunc = (arr, divergence, val) => {
    if (divergence === val) {
      data.divergenceAlert.push(divergence)
    }
    if (arr.filter(a => a === true).length >= state.general.signals) {
      obj.highlight = val
      data.highlight = val
      alertArr.push(val)
    }
  }

  const volumeAlertPositive = (arr, val) => {
    if (arr.every(a => (a === 'bull' || a === 'positive')) && arr.some(a => (a === 'bull'))) {
      obj.volumeHighlight = val
      data.highlight = val
      alertVolumeArr.push(val)
    }
  }

  const volumeAlertNegative = (arr, val) => {
    if (arr.every(a => (a === 'bear' || a === 'negative')) && arr.some(a => (a === 'bear'))) {
      obj.volumeHighlight = val
      data.highlight = val
      alertVolumeArr.push(val)
    }
  }

  if (state.general.rotationValue === 'e' || state.general.rotationValue === 'p') {
    volumeAlertPositive(obj.volumeSignal.signalValue.slice(-state.general.volumeSignal), 1)
  }

  if (state.general.rotationValue === 'e' || state.general.rotationValue === 'n') {
    volumeAlertNegative(obj.volumeSignal.signalValue.slice(-state.general.volumeSignal), 2)
  }

  const signsPositive = state.general.signs.map((item) => item[0])

  const signsNegative = state.general.signs.map((item) => item[1])

  const positiveAlert = [signsPositive.includes(obj.ma), signsPositive.includes(obj.divergence), signsPositive.includes(obj.stochasticRSI), signsPositive.includes(obj.stochasticOsc), signsPositive.includes(obj.kst), signsPositive.includes(obj.macd), signsPositive.includes(obj.wt), signsPositive.includes(obj.tsi), signsPositive.includes(obj.rsi), signsPositive.includes(obj.volumeHighlight), signsPositive.includes(obj.st)]

  const negativeAlert = [signsNegative.includes(obj.ma), signsNegative.includes(obj.divergence), signsNegative.includes(obj.stochasticRSI), signsNegative.includes(obj.stochasticOsc), signsNegative.includes(obj.kst), signsNegative.includes(obj.macd), signsNegative.includes(obj.wt), signsNegative.includes(obj.tsi), signsNegative.includes(obj.rsi), signsNegative.includes(obj.volumeHighlight), signsPositive.includes(obj.st)]

  data.show = true

  if (state.general.rotationValue === 'e' || state.general.rotationValue === 'p') {
    alertfunc(positiveAlert, obj.divergence, 1)
  }

  if (state.general.rotationValue === 'e' || state.general.rotationValue === 'n') {
    alertfunc(negativeAlert, obj.divergence, 2)
  }

  data.candlestick[j] = obj
}

export const alerts = (state, data, alertArr, alertVolumeArr, priceChange, pushNotification) => {
  if (alertArr.length || alertVolumeArr.length || data.divergenceAlert.length) {
    if (state.general.rotationValue === 'e' || state.general.rotationValue === 'p') {
      const positiveAlert = alertArr.filter((val) => {
        return val === 1
      })
      const positiveVolumeAlert = alertVolumeArr.filter((val) => {
        return val === 1
      })

      const positiveDivergence = data.divergenceAlert.filter((val) => {
        return val === 1
      })

      const strictPositive = ((positiveVolumeAlert.length >= state.general.signalsRow) || ((positiveAlert.length >= state.general.signalsRow) && (positiveVolumeAlert.length >= state.general.signalsRow)))

      const defaultPositive = (positiveAlert.length >= state.general.signalsRow)

      if (((state.general.strict ? strictPositive : defaultPositive) && (state.general.priceChangeBull === '' ? true : (priceChange <= state.general.priceChangeBull))) || (positiveDivergence.length >= state.general.signalsDivergenceRow)) {
        data.alert = true
        if (state.general.notification) pushNotification(data, positiveDivergence.length ? ('D-BULL-' + positiveDivergence.length) : 'BULL')
      }
    }

    if (state.general.rotationValue === 'e' || state.general.rotationValue === 'n') {
      const negativeAlert = alertArr.filter((val) => {
        return val === 2
      })

      const negativeVolumeAlert = alertVolumeArr.filter((val) => {
        return val === 2
      })

      const negativeDivergence = data.divergenceAlert.filter((val) => {
        return val === 2
      })

      const strictNegative = ((negativeVolumeAlert.length >= state.general.signalsRow) || ((negativeAlert.length >= state.general.signalsRow) && (negativeVolumeAlert.length >= state.general.signalsRow)))

      const defaultNegative = (negativeAlert.length >= state.general.signalsRow)

      if (((state.general.strict ? strictNegative : defaultNegative) && (state.general.priceChangeBear === '' ? true : (priceChange >= state.general.priceChangeBear))) || (negativeDivergence.length >= state.general.signalsDivergenceRow)) {
        data.alert = true
        if (state.general.notification) pushNotification(data, negativeDivergence.length ? ('D-BEAR-' + negativeDivergence.length) : 'BEAR')
      }
    }
  }
}
