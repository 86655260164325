// Identify strong RSI divergence points
export default (p, r, state) => {
  const result = {
    positive: null,
    negative: null,
    priceIndex: null
  }

  let prices = p.slice(-state.divergence.comparison); // Price values corresponding to RSI values
  let rsis = r.slice(-state.divergence.comparison); // Price values corresponding to RSI values
  let lastPrice = Number(prices[prices.length - 1])
  let lastRsi = rsis[rsis.length - 1]
  let priceMin = Math.min(...prices.slice(0, prices.length - state.divergence.tolerance))
  let priceMax = Math.max(...prices.slice(0, prices.length - state.divergence.tolerance))
  let firstRsiPositive = rsis[prices.indexOf(priceMin)]
  let firstRsiNegative = rsis[prices.indexOf(priceMax)]

  const rsiChangePositive = Math.ceil(Math.abs(firstRsiPositive - lastRsi))
  const rsiChangeNegative = Math.ceil(Math.abs(firstRsiNegative - lastRsi))
  
  if ((priceMin > lastPrice) && (firstRsiPositive < lastRsi) && (rsiChangePositive >= state.divergence.threshold) && (firstRsiPositive <= state.divergence.oversold)){
    result.positive = true
    result.priceIndex = prices.length - prices.indexOf(priceMin)
  } else if ((priceMax < lastPrice) && (firstRsiNegative > lastRsi) && (rsiChangeNegative >= state.divergence.threshold) && (firstRsiNegative >= state.divergence.overbought)) {
    result.negative = true
    result.priceIndex = prices.length - prices.indexOf(priceMax)
  }
  return result
}
