<template>
  <v-expansion-panel>
    <v-expansion-panel-title>Volume MA</v-expansion-panel-title>
    <v-expansion-panel-text>
      <v-sheet class="mx-auto">
        <v-row no-gutters>
          <v-col cols="6">
            <v-text-field v-model="stateValues.volume.period" label="MA Period" required></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select v-model="stateValues.volume.backward" :items="stateValues.volume.backwardItems" label="Backward Limit"
              required></v-select>
          </v-col>
        </v-row>
      </v-sheet>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>
<script>
import { reactive } from 'vue'
export default {
  name: 'App',
  props: {
    state: {
      type: Object
    }
  },
  setup(props) {
    const stateValues = reactive(props.state)

    return {
      stateValues
    }
  }
}
</script>
