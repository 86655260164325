<template>
  <v-table class="table" fixed-header height="100vh">
    <thead>
      <tr>
        <th colspan="19" class="text-left th-search">
          <v-text-field class="search" v-model="mainValues.searchString" label="Type to Filter..." required></v-text-field>
        </th>
      </tr>
      <tr>
        <th class="text-left">#</th>
        <th v-if="stateValues.general.exchange.length > 1" class="text-left">Exchange</th>
        <th class="text-left">Symbol</th>
        <th class="text-left"><button @click="sortList('lastPrice')">Price <font-awesome-icon
              :icon="mainValues.sortedText === 'lastPrice-up' ? ['fas', 'arrow-up'] : mainValues.sortedText === 'lastPrice-down' ? ['fas', 'arrow-down'] : []" /></button>
        </th>
        <th class="text-left"><button @click="sortList('priceChange')">P.&nbsp;Change <font-awesome-icon
              :icon="mainValues.sortedText === 'priceChange-up' ? ['fas', 'arrow-up'] : mainValues.sortedText === 'priceChange-down' ? ['fas', 'arrow-down'] : []" /></button>
        </th>
        <th class="text-left"><button @click="sortList('volume')">Volume <font-awesome-icon
              :icon="mainValues.sortedText === 'volume-up' ? ['fas', 'arrow-up'] : mainValues.sortedText === 'volume-down' ? ['fas', 'arrow-down'] : []" /></button>
        </th>
        <th class="text-left" v-if="stateValues.general.tradeType === 'futures' && stateValues.general.exchange.includes('binance')"><button
            @click="sortList('funding')">Funding&nbsp;R. <font-awesome-icon
              :icon="mainValues.sortedText === 'funding-up' ? ['fas', 'arrow-up'] : mainValues.sortedText === 'funding-down' ? ['fas', 'arrow-down'] : []" /></button>
        </th>
        <th class="text-left" v-if="stateValues.depth.open"><button @click="sortList('depthValues.percent')">Depth
            <font-awesome-icon
              :icon="mainValues.sortedText === 'depthValues.percent-up' ? ['fas', 'arrow-up'] : mainValues.sortedText === 'depthValues.percent-down' ? ['fas', 'arrow-down'] : []" /></button>
        </th>
        <th class="text-left">Int.</th>
        <th class="text-left"><span style="display: inline-block;" class="text-center">Volume<br><span
              style="font-size: 10px;">(MA)</span></span></th>
        <th class="text-center">{{ stateValues.MA.type }}<br><span style="font-size: 10px;">{{ stateValues.MA.period1 + '/' + stateValues.MA.period2
            }}</span></th>
        <th class="text-center">ST</th>
        <th class="text-center">RSI&nbsp;D.</th>
        <th class="text-center">WT</th>
        <th class="text-center">TSI</th>
        <th class="text-center">RSI</th>
        <th class="text-center">SRSI</th>
        <th class="text-center">SOSC</th>
        <th class="text-center">KST</th>
        <th class="text-center">MACD</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(item, index) in filteredCoinlist" :key="item.id">
        <template v-if="item.show">
          <tr class="table-list">
            <td :rowspan="item.candlestick.length" class="text-left tlist">{{ index + 1 }}</td>
            <td :rowspan="item.candlestick.length" v-if="stateValues.general.exchange.length > 1" class="text-left tlist">{{
              item.exchange }}</td>
            <td :rowspan="item.candlestick.length" class="text-left tlist">
              <template v-if="mainValues.tab === 4 || mainValues.watchList.find((value) => value.symbol === item.symbol)">
                <span class="remove-list" @click="removeWatchList(index)"><font-awesome-icon class="icon"
                    icon="fa-solid fa-circle-minus" /> {{ item.symbol }}</span>
              </template>
              <template v-else>
                <span class="add-list" @click="addWatchList(item)"><font-awesome-icon class="icon"
                    icon="fa-solid fa-circle-plus" /> {{ item.symbol }}</span>
              </template>
            </td>
            <td :rowspan="item.candlestick.length" class="text-left tlist">{{ item.lastPrice }}</td>
            <td :rowspan="item.candlestick.length" class="text-left tlist"><v-btn
                :class="{ 'ignore-update': mainValues.tab === 4 }"
                @click="(!mainValues.selectedSymbol.includes(item.symbol)) ? update(item.symbol) : null" size="small"
                variant="elevated" :color="item.priceChange > 0 ? 'green' : item.priceChange < 0 ? 'red' : ''"><strong>
                  <template v-if="mainValues.selectedSymbol.includes(item.symbol)">
                    <font-awesome-icon icon="fa-solid fa-spinner" spin />
                  </template>
                  <template v-else>
                    {{ item.priceChange }}%
                  </template>
                </strong></v-btn></td>
            <td :rowspan="item.candlestick.length" class="text-left tlist">{{ item.volume.toLocaleString() }}</td>
            <td :rowspan="item.candlestick.length" class="text-left tlist"
              v-if="stateValues.general.tradeType === 'futures' && stateValues.general.exchange.includes('binance')">{{ item.funding }}</td>
            <td :rowspan="item.candlestick.length" class="text-left tlist" v-if="stateValues.depth.open"><v-btn size="small"
                @click="openDepth(item)"><strong
                  :class="[item.depthValues.percent > 0 ? 'text-green' : item.depthValues.percent < 0 ? 'text-red' : '']">{{
                    item.depthValues.percent }}%</strong></v-btn></td>
            <template v-for="val in item.candlestick.slice(0, 1)" :key="val.id">
              <td @click.stop="openUrl(val.url)"
                :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
                class="text-left first-row">
                {{ val.interval }}
              </td>
              <td @click.stop="openUrl(val.url)"
                :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
                class="text-left vol-ma first-row">
                <div class="volume-alert"
                  :style="{ 'border-color': val.volumeHighlight === 1 ? 'green' : val.volumeHighlight === 2 ? 'red' : 'transparent' }">
                  <span v-for="(item, i) in val.volumeSignal.signal" :key="i"
                    :style="{ 'background-color': item, 'height': val.volumeSignal.percent[i] + '%' }"></span>
                </div>
              </td>
              <td @click.stop="openUrl(val.url)"
                :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
                class="text-center first-row"
                :style="{ 'color': val.ma === 1 ? 'green' : val.ma === 2 ? 'red' : 'darkblue' }">
                <template v-if="val.ma === 1">
                  <font-awesome-icon :icon="['far', 'circle-up']" />
                </template>
                <template v-else-if="val.ma === 2">
                  <font-awesome-icon :icon="['far', 'circle-down']" />
                </template>
                <template v-else-if="val.ma === 3 || val.ma === 4">
                  <span class="round" :style="{ 'background-color': val.ma === 3 ? 'green' : 'red' }"></span>
                </template>
                <template v-else-if="val.ma === 5 || val.ma === 6">
                  <font-awesome-icon :icon="val.ma === 5 ? ['fas', 'arrow-trend-up'] : ['fas', 'arrow-trend-down']" />
                </template>
                <template v-else-if="val.ma === 7 || val.ma === 8">
                  <font-awesome-icon :icon="val.ma === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
                </template>
              </td>
              <td @click.stop="openUrl(val.url)"
                :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
                class="text-center first-row"
                :style="{ 'color': val.st === 1 ? 'green' : val.st === 2 ? 'red' : 'darkblue' }">
                <template v-if="val.st === 1">
                  <font-awesome-icon :icon="['far', 'circle-up']" />
                </template>
                <template v-else-if="val.st === 2">
                  <font-awesome-icon :icon="['far', 'circle-down']" />
                </template>
                <template v-else-if="val.st === 3 || val.st === 4">
                  <span class="round" :style="{ 'background-color': val.st === 3 ? 'green' : 'red' }"></span>
                </template>
                <template v-else-if="val.st === 7 || val.st === 8">
                  <font-awesome-icon :icon="val.st === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
                </template>
              </td>
              <td @click.stop="openUrl(val.url)"
                :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
                class="text-center first-row"
                :style="{ 'color': val.divergence === 1 ? 'green' : val.divergence === 2 ? 'red' : 'darkblue' }">
                <div style="white-space: nowrap">
                  <template v-if="val.divergence === 1">
                    <font-awesome-icon :icon="['far', 'circle-up']" />
                  </template>
                  <template v-else-if="val.divergence === 2">
                    <font-awesome-icon :icon="['far', 'circle-down']" />
                  </template>
                  <template v-if="(val.divergence === 1 || val.divergence === 2)">
                    <span class="divergence-index">{{ '(' + val.divergenceIndex + ')' }}</span>
                  </template>
                </div>
              </td>
              <td @click.stop="openUrl(val.url)"
                :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
                class="text-center first-row"
                :style="{ 'color': val.wt === 1 ? 'green' : val.wt === 2 ? 'red' : 'darkblue' }">
                <template v-if="val.wt === 1">
                  <font-awesome-icon :icon="['far', 'circle-up']" />
                </template>
                <template v-else-if="val.wt === 2">
                  <font-awesome-icon :icon="['far', 'circle-down']" />
                </template>
                <template v-else-if="val.wt === 3 || val.wt === 4">
                  <span class="round" :style="{ 'background-color': val.wt === 3 ? 'green' : 'red' }"></span>
                </template>
                <template v-else-if="val.wt === 5 || val.wt === 6">
                  <font-awesome-icon :icon="val.wt === 5 ? ['fas', 'arrow-trend-up'] : ['fas', 'arrow-trend-down']" />
                </template>
                <template v-else-if="val.wt === 7 || val.wt === 8">
                  <font-awesome-icon :icon="val.wt === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
                </template>
              </td>
              <td @click.stop="openUrl(val.url)"
                :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
                class="text-center first-row"
                :style="{ 'color': val.tsi === 1 ? 'green' : val.tsi === 2 ? 'red' : 'darkblue' }">
                <template v-if="val.tsi === 1">
                  <font-awesome-icon :icon="['far', 'circle-up']" />
                </template>
                <template v-else-if="val.tsi === 2">
                  <font-awesome-icon :icon="['far', 'circle-down']" />
                </template>
                <template v-else-if="val.tsi === 3 || val.tsi === 4">
                  <span class="round" :style="{ 'background-color': val.tsi === 3 ? 'green' : 'red' }"></span>
                </template>
                <template v-else-if="val.tsi === 5 || val.tsi === 6">
                  <font-awesome-icon :icon="val.tsi === 5 ? ['fas', 'arrow-trend-up'] : ['fas', 'arrow-trend-down']" />
                </template>
                <template v-else-if="val.tsi === 7 || val.tsi === 8">
                  <font-awesome-icon :icon="val.tsi === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
                </template>
              </td>
              <td @click.stop="openUrl(val.url)"
                :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
                class="text-center first-row"
                :style="{ 'color': val.rsi === 1 ? 'green' : val.rsi === 2 ? 'red' : 'darkblue' }">
                <template v-if="val.rsi === 1">
                  <font-awesome-icon :icon="['far', 'circle-up']" />
                </template>
                <template v-else-if="val.rsi === 2">
                  <font-awesome-icon :icon="['far', 'circle-down']" />
                </template>
                <template v-else-if="val.rsi === 3 || val.rsi === 4">
                  <span class="round" :style="{ 'background-color': val.rsi === 3 ? 'green' : 'red' }"></span>
                </template>
                <template v-else-if="val.rsi === 5 || val.rsi === 6">
                  <font-awesome-icon :icon="val.rsi === 5 ? ['fas', 'arrow-trend-up'] : ['fas', 'arrow-trend-down']" />
                </template>
                <template v-else-if="val.rsi === 7 || val.rsi === 8">
                  <font-awesome-icon :icon="val.rsi === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
                </template>
              </td>
              <td @click.stop="openUrl(val.url)"
                :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
                class="text-center first-row"
                :style="{ 'color': val.stochasticRSI === 1 ? 'green' : val.stochasticRSI === 2 ? 'red' : 'darkblue' }">
                <template v-if="val.stochasticRSI === 1">
                  <font-awesome-icon :icon="['far', 'circle-up']" />
                </template>
                <template v-else-if="val.stochasticRSI === 2">
                  <font-awesome-icon :icon="['far', 'circle-down']" />
                </template>
                <template v-else-if="val.stochasticRSI === 3 || val.stochasticRSI === 4">
                  <span class="round" :style="{ 'background-color': val.stochasticRSI === 3 ? 'green' : 'red' }"></span>
                </template>
                <template v-else-if="val.stochasticRSI === 5 || val.stochasticRSI === 6">
                  <font-awesome-icon
                    :icon="val.stochasticRSI === 5 ? ['fas', 'arrow-trend-up'] : ['fas', 'arrow-trend-down']" />
                </template>
                <template v-else-if="val.stochasticRSI === 7 || val.stochasticRSI === 8">
                  <font-awesome-icon :icon="val.stochasticRSI === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
                </template>
              </td>
              <td @click.stop="openUrl(val.url)"
                :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
                class="text-center first-row"
                :style="{ 'color': val.stochasticOsc === 1 ? 'green' : val.stochasticOsc === 2 ? 'red' : 'darkblue' }">
                <template v-if="val.stochasticOsc === 1">
                  <font-awesome-icon :icon="['far', 'circle-up']" />
                </template>
                <template v-else-if="val.stochasticOsc === 2">
                  <font-awesome-icon :icon="['far', 'circle-down']" />
                </template>
                <template v-else-if="val.stochasticOsc === 3 || val.stochasticOsc === 4">
                  <span class="round" :style="{ 'background-color': val.stochasticOsc === 3 ? 'green' : 'red' }"></span>
                </template>
                <template v-else-if="val.stochasticOsc === 5 || val.stochasticOsc === 6">
                  <font-awesome-icon
                    :icon="val.stochasticOsc === 5 ? ['fas', 'arrow-trend-up'] : ['fas', 'arrow-trend-down']" />
                </template>
                <template v-else-if="val.stochasticOsc === 7 || val.stochasticOsc === 8">
                  <font-awesome-icon :icon="val.stochasticOsc === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
                </template>
              </td>
              <td @click.stop="openUrl(val.url)"
                :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
                class="text-center first-row"
                :style="{ 'color': val.kst === 1 ? 'green' : val.kst === 2 ? 'red' : 'darkblue' }">
                <template v-if="val.kst === 1">
                  <font-awesome-icon :icon="['far', 'circle-up']" />
                </template>
                <template v-else-if="val.kst === 2">
                  <font-awesome-icon :icon="['far', 'circle-down']" />
                </template>
                <template v-else-if="val.kst === 3 || val.kst === 4">
                  <span class="round" :style="{ 'background-color': val.kst === 3 ? 'green' : 'red' }"></span>
                </template>
                <template v-else-if="val.kst === 5 || val.kst === 6">
                  <font-awesome-icon :icon="val.kst === 5 ? ['fas', 'arrow-trend-up'] : ['fas', 'arrow-trend-down']" />
                </template>
                <template v-else-if="val.kst === 7 || val.kst === 8">
                  <font-awesome-icon :icon="val.kst === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
                </template>
              </td>
              <td @click.stop="openUrl(val.url)"
                :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
                class="text-center first-row"
                :style="{ 'color': val.macd === 1 ? 'green' : val.macd === 2 ? 'red' : 'darkblue' }">
                <template v-if="val.macd === 1">
                  <font-awesome-icon :icon="['far', 'circle-up']" />
                </template>
                <template v-else-if="val.macd === 2">
                  <font-awesome-icon :icon="['far', 'circle-down']" />
                </template>
                <template v-else-if="val.macd === 3 || val.macd === 4">
                  <span class="round" :style="{ 'background-color': val.macd === 3 ? 'green' : 'red' }"></span>
                </template>
                <template v-else-if="val.macd === 5 || val.macd === 6">
                  <font-awesome-icon :icon="val.macd === 5 ? ['fas', 'arrow-trend-up'] : ['fas', 'arrow-trend-down']" />
                </template>
                <template v-else-if="val.macd === 7 || val.macd === 8">
                  <font-awesome-icon :icon="val.macd === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
                </template>
              </td>
            </template>
          </tr>
          <tr v-for="(val, index) in item.candlestick.slice(1)" :key="val.id" class="table-list row-list"
            :class="{ rlist: index === item.candlestick.slice(1).length - 1 }" @click="openUrl(val.url)">
            <td :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
              class="text-left">
              {{ val.interval }}
            </td>
            <td @click.stop="openUrl(val.url)"
              :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
              class="text-left vol-ma">
              <div class="volume-alert"
                :style="{ 'border-color': val.volumeHighlight === 1 ? 'green' : val.volumeHighlight === 2 ? 'red' : 'transparent' }">
                <span v-for="(item, i) in val.volumeSignal.signal" :key="i"
                  :style="{ 'background-color': item, 'height': val.volumeSignal.percent[i] + '%' }"></span>
              </div>
            </td>
            <td :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
              class="text-center" :style="{ 'color': val.ma === 1 ? 'green' : val.ma === 2 ? 'red' : 'darkblue' }">
              <template v-if="val.ma === 1">
                <font-awesome-icon :icon="['far', 'circle-up']" />
              </template>
              <template v-else-if="val.ma === 2">
                <font-awesome-icon :icon="['far', 'circle-down']" />
              </template>
              <template v-else-if="val.ma === 3 || val.ma === 4">
                <span class="round" :style="{ 'background-color': val.ma === 3 ? 'green' : 'red' }"></span>
              </template>
              <template v-else-if="val.ma === 5 || val.ma === 6">
                <font-awesome-icon :icon="val.ma === 5 ? ['fas', 'arrow-trend-up'] : ['fas', 'arrow-trend-down']" />
              </template>
              <template v-else-if="val.ma === 7 || val.ma === 8">
                <font-awesome-icon :icon="val.ma === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
              </template>
            </td>
            <td @click.stop="openUrl(val.url)"
              :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
              class="text-center" :style="{ 'color': val.st === 1 ? 'green' : val.st === 2 ? 'red' : 'darkblue' }">
              <template v-if="val.st === 1">
                <font-awesome-icon :icon="['far', 'circle-up']" />
              </template>
              <template v-else-if="val.st === 2">
                <font-awesome-icon :icon="['far', 'circle-down']" />
              </template>
              <template v-else-if="val.st === 3 || val.st === 4">
                <span class="round" :style="{ 'background-color': val.st === 3 ? 'green' : 'red' }"></span>
              </template>
              <template v-else-if="val.st === 7 || val.st === 8">
                <font-awesome-icon :icon="val.st === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
              </template>
            </td>
            <td :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
              class="text-center"
              :style="{ 'color': val.divergence === 1 ? 'green' : val.divergence === 2 ? 'red' : 'darkblue' }">
              <div style="white-space: nowrap">
                <template v-if="val.divergence === 1">
                  <font-awesome-icon :icon="['far', 'circle-up']" />
                </template>
                <template v-else-if="val.divergence === 2">
                  <font-awesome-icon :icon="['far', 'circle-down']" />
                </template>
                <template v-if="(val.divergence === 1 || val.divergence === 2)">
                  <span class="divergence-index">{{ '(' + val.divergenceIndex + ')' }}</span>
                </template>
              </div>
            </td>
            <td @click.stop="openUrl(val.url)"
              :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
              class="text-center" :style="{ 'color': val.wt === 1 ? 'green' : val.wt === 2 ? 'red' : 'darkblue' }">
              <template v-if="val.wt === 1">
                <font-awesome-icon :icon="['far', 'circle-up']" />
              </template>
              <template v-else-if="val.wt === 2">
                <font-awesome-icon :icon="['far', 'circle-down']" />
              </template>
              <template v-else-if="val.wt === 3 || val.wt === 4">
                <span class="round" :style="{ 'background-color': val.wt === 3 ? 'green' : 'red' }"></span>
              </template>
              <template v-else-if="val.wt === 5 || val.wt === 6">
                <font-awesome-icon :icon="val.wt === 5 ? ['fas', 'arrow-trend-up'] : ['fas', 'arrow-trend-down']" />
              </template>
              <template v-else-if="val.wt === 7 || val.wt === 8">
                <font-awesome-icon :icon="val.wt === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
              </template>
            </td>
            <td @click.stop="openUrl(val.url)"
              :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
              class="text-center" :style="{ 'color': val.tsi === 1 ? 'green' : val.tsi === 2 ? 'red' : 'darkblue' }">
              <template v-if="val.tsi === 1">
                <font-awesome-icon :icon="['far', 'circle-up']" />
              </template>
              <template v-else-if="val.tsi === 2">
                <font-awesome-icon :icon="['far', 'circle-down']" />
              </template>
              <template v-else-if="val.tsi === 3 || val.tsi === 4">
                <span class="round" :style="{ 'background-color': val.tsi === 3 ? 'green' : 'red' }"></span>
              </template>
              <template v-else-if="val.tsi === 5 || val.tsi === 6">
                <font-awesome-icon :icon="val.tsi === 5 ? ['fas', 'arrow-trend-up'] : ['fas', 'arrow-trend-down']" />
              </template>
              <template v-else-if="val.tsi === 7 || val.tsi === 8">
                <font-awesome-icon :icon="val.tsi === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
              </template>
            </td>
            <td :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
              class="text-center" :style="{ 'color': val.rsi === 1 ? 'green' : val.rsi === 2 ? 'red' : 'darkblue' }">
              <template v-if="val.rsi === 1">
                <font-awesome-icon :icon="['far', 'circle-up']" />
              </template>
              <template v-else-if="val.rsi === 2">
                <font-awesome-icon :icon="['far', 'circle-down']" />
              </template>
              <template v-else-if="val.rsi === 3 || val.rsi === 4">
                <span class="round" :style="{ 'background-color': val.rsi === 3 ? 'green' : 'red' }"></span>
              </template>
              <template v-else-if="val.rsi === 5 || val.rsi === 6">
                <font-awesome-icon :icon="val.rsi === 5 ? ['fas', 'arrow-trend-up'] : ['fas', 'arrow-trend-down']" />
              </template>
              <template v-else-if="val.rsi === 7 || val.rsi === 8">
                <font-awesome-icon :icon="val.rsi === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
              </template>
            </td>
            <td :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
              class="text-center"
              :style="{ 'color': val.stochasticRSI === 1 ? 'green' : val.stochasticRSI === 2 ? 'red' : 'darkblue' }">
              <template v-if="val.stochasticRSI === 1">
                <font-awesome-icon :icon="['far', 'circle-up']" />
              </template>
              <template v-else-if="val.stochasticRSI === 2">
                <font-awesome-icon :icon="['far', 'circle-down']" />
              </template>
              <template v-else-if="val.stochasticRSI === 3 || val.stochasticRSI === 4">
                <span class="round" :style="{ 'background-color': val.stochasticRSI === 3 ? 'green' : 'red' }"></span>
              </template>
              <template v-else-if="val.stochasticRSI === 5 || val.stochasticRSI === 6">
                <font-awesome-icon
                  :icon="val.stochasticRSI === 5 ? ['fas', 'arrow-trend-up'] : ['fas', 'arrow-trend-down']" />
              </template>
              <template v-else-if="val.stochasticRSI === 7 || val.stochasticRSI === 8">
                <font-awesome-icon :icon="val.stochasticRSI === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
              </template>
            </td>
            <td :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
              class="text-center"
              :style="{ 'color': val.stochasticOsc === 1 ? 'green' : val.stochasticOsc === 2 ? 'red' : 'darkblue' }">
              <template v-if="val.stochasticOsc === 1">
                <font-awesome-icon :icon="['far', 'circle-up']" />
              </template>
              <template v-else-if="val.stochasticOsc === 2">
                <font-awesome-icon :icon="['far', 'circle-down']" />
              </template>
              <template v-else-if="val.stochasticOsc === 3 || val.stochasticOsc === 4">
                <span class="round" :style="{ 'background-color': val.stochasticOsc === 3 ? 'green' : 'red' }"></span>
              </template>
              <template v-else-if="val.stochasticOsc === 5 || val.stochasticOsc === 6">
                <font-awesome-icon
                  :icon="val.stochasticOsc === 5 ? ['fas', 'arrow-trend-up'] : ['fas', 'arrow-trend-down']" />
              </template>
              <template v-else-if="val.stochasticOsc === 7 || val.stochasticOsc === 8">
                <font-awesome-icon :icon="val.stochasticOsc === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
              </template>
            </td>
            <td :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
              class="text-center" :style="{ 'color': val.kst === 1 ? 'green' : val.kst === 2 ? 'red' : 'darkblue' }">
              <template v-if="val.kst === 1">
                <font-awesome-icon :icon="['far', 'circle-up']" />
              </template>
              <template v-else-if="val.kst === 2">
                <font-awesome-icon :icon="['far', 'circle-down']" />
              </template>
              <template v-else-if="val.kst === 3 || val.kst === 4">
                <span class="round" :style="{ 'background-color': val.kst === 3 ? 'green' : 'red' }"></span>
              </template>
              <template v-else-if="val.kst === 5 || val.kst === 6">
                <font-awesome-icon :icon="val.kst === 5 ? ['fas', 'arrow-trend-up'] : ['fas', 'arrow-trend-down']" />
              </template>
              <template v-else-if="val.kst === 7 || val.kst === 8">
                <font-awesome-icon :icon="val.kst === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
              </template>
            </td>
            <td :class="[val.highlight === 1 ? 'highlight-green' : val.highlight === 2 ? 'highlight-red' : '']"
              class="text-center" :style="{ 'color': val.macd === 1 ? 'green' : val.macd === 2 ? 'red' : 'darkblue' }">
              <template v-if="val.macd === 1">
                <font-awesome-icon :icon="['far', 'circle-up']" />
              </template>
              <template v-else-if="val.macd === 2">
                <font-awesome-icon :icon="['far', 'circle-down']" />
              </template>
              <template v-else-if="val.macd === 3 || val.macd === 4">
                <span class="round" :style="{ 'background-color': val.macd === 3 ? 'green' : 'red' }"></span>
              </template>
              <template v-else-if="val.macd === 5 || val.macd === 6">
                <font-awesome-icon :icon="val.macd === 5 ? ['fas', 'arrow-trend-up'] : ['fas', 'arrow-trend-down']" />
              </template>
              <template v-else-if="val.macd === 7 || val.macd === 8">
                <font-awesome-icon :icon="val.macd === 7 ? ['fas', 'arrow-up'] : ['fas', 'arrow-down']" />
              </template>
            </td>
          </tr>
        </template>
      </template>
    </tbody>
  </v-table>
  <v-row justify="center">
    <depthModal :depth="stateValues.depth" :main="mainValues" ref="depthComponent"></depthModal>
  </v-row>
</template>
<script>
import { reactive, ref, computed } from 'vue'
import { clone } from 'lodash'
import depthModal from '../modals/depth.vue'
import { filterOptions } from '../../methods/helpers'
export default {
  name: 'App',
  components: {
    depthModal
  },
  props: {
    state: {
      type: Object
    },
    main: {
      type: Object
    }
  },
  setup(props, { expose }) {
    const stateValues = reactive(props.state)
    const mainValues = reactive(props.main)

    const sortList = (value) => {
      mainValues.sortedList = !mainValues.sortedList
      if (mainValues.sortedList) {
        mainValues.sortedText = value + '-up'
      } else {
        mainValues.sortedText = value + '-down'
      }

      if (mainValues.tab === 4) {
          if (value === 'depthValues.percent') {
            mainValues.watchList = mainValues.watchList.sort((a, b) => {
              return mainValues.sortedList ? +b.depthValues.percent - +a.depthValues.percent : +a.depthValues.percent - +b.depthValues.percent
            })
          } else {
            mainValues.watchList = mainValues.watchList.sort((a, b) => {
              return mainValues.sortedList ? +b[value] - +a[value] : +a[value] - +b[value]
            })
          }
      } else {
        if (value === 'depthValues.percent') {
          mainValues.coinlist = mainValues.coinlist.sort((a, b) => {
            return mainValues.sortedList ? +b.depthValues.percent - +a.depthValues.percent : +a.depthValues.percent - +b.depthValues.percent
          })
        } else {
          mainValues.coinlist = mainValues.coinlist.sort((a, b) => {
            return mainValues.sortedList ? +b[value] - +a[value] : +a[value] - +b[value]
          })
        }
      }
    }

    const addWatchList = (item) => {
      if (!mainValues.watchList.find((value) => value.symbol === item.symbol)) {
        mainValues.watchList.push(item)
        localStorage.setItem('watchList', JSON.stringify(mainValues.watchList))
      }
    }

    const removeWatchList = (index) => {
      mainValues.watchList.splice(index, 1)
      localStorage.setItem('watchList', JSON.stringify(mainValues.watchList))
    }

    const openUrl = (url) => {
      window.open(url, '_blank')
    }

    const update = (symbol) => {
      if (!symbol) mainValues.loading = '0'
      mainValues.selectedSymbol.push(symbol)
      mainValues.count = clone(stateValues.general.repeatInterval)
      mainValues.updateData(symbol)
    }

    const openDepth = (item) => {
      if (depthComponent.value) {
        depthComponent.value.openDepth(item);
      }
    };

    const filteredCoinlist = computed(() => {
      const optionsArray = clone(mainValues.tab === 4 ? mainValues.watchList : mainValues.coinlist)
      return filterOptions(optionsArray, mainValues.searchString)
    })

    const depthComponent = ref(null);

    expose({ update })

    return {
      stateValues,
      mainValues,
      sortList,
      addWatchList,
      removeWatchList,
      openUrl,
      update,
      openDepth,
      depthComponent,
      filteredCoinlist
    }
  }
}
</script>
