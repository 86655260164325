import axios from 'axios'
import { calculatePercent } from '../helpers'

export default async (depth, inputs, item) => {
    const exchangeOrderDepth = await axios.get(`${inputs[item.exchange.toLowerCase()].depth}?${inputs[item.exchange.toLowerCase()].propertyNames.symbol}=${item[inputs[item.exchange.toLowerCase()].propertyNames.symbol]}&limit=${depth.limit}`)

    const calculateTotalVolume = (orderBook) => {
        const totalBidVolume = orderBook.bids.reduce((total, bid) => total + parseFloat(bid[1]), 0);
        const totalAskVolume = orderBook.asks.reduce((total, ask) => total + parseFloat(ask[1]), 0);
        return totalBidVolume + totalAskVolume;
    }

    const findWhaleOrders = (orderBook, percentageThreshold) => {
        const totalVolume = calculateTotalVolume(orderBook)
        const dynamicThreshold = totalVolume * (percentageThreshold / 100);

        const bids = orderBook.bids.filter(bid => parseFloat(bid[1]) >= dynamicThreshold);
        const asks = orderBook.asks.filter(ask => parseFloat(ask[1]) >= dynamicThreshold);

        const reduceBids = +(bids.reduce((total, bid) => total + parseFloat(bid[1]), 0)).toFixed(0);
        const reduceAsks = +(asks.reduce((total, ask) => total + parseFloat(ask[1]), 0)).toFixed(0);
        return { bids, asks, reduceBids, reduceAsks };
    }

    const { bids, asks, reduceBids, reduceAsks } = findWhaleOrders(exchangeOrderDepth.data, depth.threshold);

    let percent = null

    if (reduceBids >= reduceAsks) {
        percent = (calculatePercent(reduceBids, reduceAsks) - 100).toFixed(2)
    } else {
        percent = (-calculatePercent(reduceAsks, reduceBids) - 100).toFixed(2)
    }

    return {
        bids,
        asks,
        reduceBids,
        reduceAsks,
        percent
    }
}