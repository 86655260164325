import { SuperTrend } from '@debut/indicators';

export default (state, obj) => {
  const period = state.st.period;
  const factor = state.st.factor;
  const threshold = state.st.threshold;

  const supertrend = new SuperTrend(period, factor);

  let previousClose = null;
  let previousSuperTrend = null;
  let currentTrend = null;

  // Bu değişken, son üç kapanışa ait trend sonuçlarını tutacak
  let trendResult = null;

  for (let index = 0; index < obj.close.length; index++) {
    const candleClose = obj.close[index];
    let supertrendValue = JSON.stringify(supertrend.nextValue(obj.high[index], obj.low[index], candleClose));

    if (!supertrendValue) continue;

    const superTrend = JSON.parse(supertrendValue).superTrend;

    const lastCandle = (index === obj.close.length - 1);

    // Sondan üçüncü, ikinci ve son kapanışlar için kontrolleri gerçekleştiriyoruz
    if (index >= obj.close.length - threshold) {
      // Eğer önceki kapanış SuperTrend'in altındaysa ve şu anki kapanış SuperTrend'in üstündeyse, bullish trend
      if (previousClose < previousSuperTrend && candleClose > superTrend) {
        currentTrend = 'bullish';
        trendResult = lastCandle ? 7 : 1; // Bullish trend
      }

      // Eğer önceki kapanış SuperTrend'in üstündeyse ve şu anki kapanış SuperTrend'in altındaysa, bearish trend
      if (previousClose > previousSuperTrend && candleClose < superTrend) {
        currentTrend = 'bearish';
        trendResult = lastCandle ? 8 : 2; // Bearish trend
      }

      // Eğer yukarıdaki koşullar sağlanmadıysa, son muma bakarak karar veriyoruz
    }

    if (!currentTrend && lastCandle) {
      if (candleClose > superTrend) {
        currentTrend = 'bullish';
        trendResult = 3; // Bullish trend (son mumda)
      } else if (candleClose < superTrend) {
        currentTrend = 'bearish';
        trendResult = 4; // Bearish trend (son mumda)
      }
    }

    // Şu anki kapanışı ve SuperTrend değerini bir sonraki döngü için kaydet
    previousClose = candleClose;
    previousSuperTrend = superTrend;
  }

  // Son üç kapanış için trend sonucunu döndür
  return trendResult;
};
