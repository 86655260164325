<template>
  <v-expansion-panel>
    <v-expansion-panel-title>RSI Divergence</v-expansion-panel-title>
    <v-expansion-panel-text>
      <v-sheet class="mx-auto">
        <v-row no-gutters>
          <v-col cols="6" sm="4">
            <v-text-field v-model="stateValues.divergence.period" label="RSI Period" required></v-text-field>
          </v-col>
          <v-col cols="6" sm="4">
            <v-text-field v-model="stateValues.divergence.threshold" label="Min. RSI difference" required></v-text-field>
          </v-col>
          <v-col cols="6" sm="4">
            <v-text-field v-model="stateValues.divergence.comparison" label="Compare period" required></v-text-field>
          </v-col>
          <v-col cols="6" :sm="stateValues.general.rotationValue === 'e' ? 4 : 6">
            <v-text-field v-model="stateValues.divergence.tolerance" label="Backward tolerance" required></v-text-field>
          </v-col>
          <v-col v-if="stateValues.general.rotationValue === 'e' || stateValues.general.rotationValue === 'p'" cols="6"
            :sm="stateValues.general.rotationValue === 'e' ? 4 : 6">
            <v-text-field v-model="stateValues.divergence.oversold" label="Oversold limit less than" required></v-text-field>
          </v-col>
          <v-col v-if="stateValues.general.rotationValue === 'e' || stateValues.general.rotationValue === 'n'" cols="6"
            :sm="stateValues.general.rotationValue === 'e' ? 4 : 6">
            <v-text-field v-model="stateValues.divergence.overbought" label="Overbought limit higher than" required></v-text-field>
          </v-col>
        </v-row>
      </v-sheet>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>
<script>
import { reactive } from 'vue'
export default {
  name: 'App',
  props: {
    state: {
      type: Object
    }
  },
  setup(props) {
    const stateValues = reactive(props.state)

    return {
      stateValues
    }
  }
}
</script>
