import { RSI, SMA, EMA } from 'technicalindicators'
import findSignal from './findSignal'

export default (state, obj) => {
   const inputRSICalculate = {
     values : obj.close,
     period : state.rsi.period
   };
   const currRsi = RSI.calculate(inputRSICalculate)

   const inputMACalculate = {
     values : currRsi,
     period : state.rsi.period
   };

   const MAType = state.rsi.type === 'SMA' ? SMA : EMA

   const rsiMA = MAType.calculate(inputMACalculate)

   const rsiResult = currRsi.slice(state.rsi.period - 1).map((value, index) => {
     return {
       rsi: value,
       signal: rsiMA[index]
     }
   })
   
   return findSignal(rsiResult, state.rsi)
}