import { EMA, SMA } from 'technicalindicators'
import findMASignal from './findMASignal'

export default (state, obj) => {
  const SMAInput = {
    values : obj.close,
    period : state.MA.period2
  };

  const SMAInput2 = {
    values : obj.close,
    period : state.MA.period1
  };

  const MA = state.MA.type === 'SMA' ? SMA : EMA
  
  return findMASignal(MA.calculate(SMAInput), MA.calculate(SMAInput2),  state.MA)
}