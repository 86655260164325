<template>
  <v-expansion-panel>
    <v-expansion-panel-title>Depth</v-expansion-panel-title>
    <v-expansion-panel-text>
      <v-sheet class="mx-auto">
        <v-row no-gutters>
          <v-col cols="6">
            <v-switch v-model="stateValues.depth.open" label="Open Depth" color="green"></v-switch>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="stateValues.depth.threshold" label="Whales Threshold" required
              :disabled="!stateValues.depth.open"></v-text-field>
          </v-col>
        </v-row>
      </v-sheet>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>
<script>
import { reactive } from 'vue'
export default {
    name: 'App',
    props: {
        state: {
            type: Object
        }
    },
    setup(props) {
        const stateValues = reactive(props.state)

        return {
            stateValues
        }
    }
}
</script>
